import React, { useState } from "react";
import { CSSProperties } from "@material-ui/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import clsx from "clsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "react-native-elements";
import { Link } from "react-router-dom";
const configJSON = require("../config.js");
const useStyles = makeStyles((theme: Theme) => ({

  root: {
    flexGrow: 1,
    margin: "0 auto",
    maxWidth: 1229,
    marginTop: 52,
    paddingBottom: "171px",
    "@media(max-width:600px)": {
      marginTop: 30,
      paddingBottom:"90px"
    },
  },
  red: {
    color: "red",
    marginTop: "10px",
  },
  subParagraph: {
    lineHeight: "22px",
    marginTop: "13px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px",
    color: "#B1B3C3",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textFieldStyle: {
    "& .Mui-focused fieldset": {
      borderColor: "#D9D9D9!important",
    },
    "& label.Mui-focused": {
      color: "#D9D9D9",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px",
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#D9D9D9",
    },
    "& .MuiOutlinedInput-root": {
      cursor: "pointer",
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#D9D9D9",
      },
      "&:hover fieldset": {
        borderColor: "#D9D9D9",
      },

    },
    width: "100%",
    height: "48px",
  },
  invalidTextFieldStyle: {
    "& label.Mui-focused": {
      color: "#ff0000",
    },
    "& .Mui-focused fieldset": {
      borderColor: "#ff0000!important",
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px",
    },

    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#ff0000",
    },
    "& .MuiOutlinedInput-root": {
      cursor: "pointer",
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#ff0000",
      },
      "&:hover fieldset": {
        borderColor: "#ff0000",
      },

    },
    width: "100%",
    height: "48px",
  },
  textStyle: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
    borderRadius: "8px",
    color: "#101010"
  },
  textStyle2: {
    textDecoration: "none",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#595959",
    lineHeight: "24px",
    fontSize: "15px",
    height: "100%"
  },
  dropZoneField: {
    fontWeight: 700,
    fontFamily: "Lato",
    textDecoration: "none",
    color: "#595959",
    fontSize: "15px",
    height: "100%",
    lineHeight: "24px",
    background: "#efefef"
  },
  headingStyle: {
    marginBottom: "8px",
  },
  innerBox: {
    marginBottom: "40px",
    width: "100%",
    maxWidth: "480px",
    "@media(max-width:500px)": {
      maxWidth: "100%",
      padding: "0 15px",
    },
  },
  innerBox3: {
    marginBottom: "42px",
    maxWidth: "480px",
    width: "100%",
    "@media(max-width:500px)": {
      padding: "0 15px",
      maxWidth: "100%",
    },
  },
  innerBox2: {
    marginBottom: "10px",
  },
  customButton: {
    color: "#101010",
    fontWeight: 500,
    fontFamily: "Lato",
    letterSpacing: "0.4px",
    fontSize: "15px",
    width: "184px",
    height: "56px",
    borderRadius: "8px",
    textAlign: "center" as CSSProperties["textAlign"],
    padding: "16px 12px",
    backgroundColor: "#43D270",
    "&:hover": {
      backgroundColor: "#3ca15e",
    },
    "@media(max-width:600px)": {
      fontSize: "12px",
      width: "100px",
      padding: "12px 20px",
    },
    "&.MuiButton-contained": {
      boxShadow: "unset",
    },
  },
  disabledCustomButton: {
    color: "#000000",
    fontFamily: "Lato",
    fontSize: "15px",
    width: "184px",
    textAlign: "center" as CSSProperties["textAlign"],
    height: "56px",
    borderRadius: "8px",
    fontWeight: 500,
    padding: "16px 12px",
    letterSpacing: "0.4px",
    backgroundColor: "#B1B3C3",
    "@media(max-width:600px)": {
      width: "100px",
      padding: "12px 20px",
      fontSize: "12px",
    },
    "&.MuiButton-contained": {
      boxShadow: "unset",
    },
  },
  fileInput: {
    display: "none",
  },
  fileInput2: {
    cursor: "pointer",
  },
  errorMessage: {
    color: "#ff0000",
    lineHeight: 1,
    marginTop: "15px",
    fontFamily: "Lato"
  }
}));

const GreenCheckbox = withStyles({
  root: {
    color: "gray",
    marginBottom: "26px",
    "&$checked": {
      color: "#43d270",
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

interface FormData {
  job_position_name: string,
  email_address: string;
  first_name: string;
  last_name: string;
  attach_cv_file: any;
  attach_cover_letter_file: any;
  terms_conditions_agree: number;
}

interface JobsFormPropTypes {
  onSubmit?: any;
  position: string;
  resData?: any;
}



export const handleDrag = (e: any, fieldName: string, setDragableField: any) => {
  e.preventDefault();
  e.stopPropagation();
  if (e.type === "dragenter" || e.type === "dragover") {
    setDragableField(fieldName)
  } else if (e.type === "dragleave") {
    setDragableField("")
  }
};

export const handleDrop = (e: any, fieldName: string, setDragableField?: any, setFormData?: any, formData?: any) => {
  e.preventDefault();
  e.stopPropagation();
  if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    setFormData && setFormData({
      ...formData,
      [fieldName]: e.dataTransfer.files['0'],
    });
    setDragableField && setDragableField("")
  }
};

export const checkFieldValidation = (fieldsData: any, formData: any, isValidEmail: any) => {

  if (!formData.terms_conditions_agree) {
    return false;
  }

  return fieldsData.every((item: any) => {
    if (item.isMandatory) {
      const fieldValue = formData[item.fieldName as keyof FormData];
      if (fieldValue === "") {
        return false;
      }
      if (item.type === "email" && !isValidEmail(fieldValue)) {
        return false;
      }
      if (item.type === "file" && fieldValue === null) {
        return false;
      }
      return true;
    }
    return true;
  });
}

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

const JobsForm = ({onSubmit, position, resData}: JobsFormPropTypes) => {

  const [formData, setFormData] = useState<FormData>({
    job_position_name: position ?? "",
    email_address: "",
    first_name: "",
    last_name: "",
    attach_cv_file: null,
    attach_cover_letter_file: null,
    terms_conditions_agree: 0,
  })

  const [dragableField, setDragableField] = useState("")
  const [showErrors, setShowErrors] = useState(false)
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const classes = useStyles();

  const handleFileChange = (event: any, fieldName: string) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFormData({
        ...formData,
        [fieldName]: file,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: null,
      });
    }
  };

  const handleIconClick = (fieldName: any) => {
    const fileInput = document.getElementById(`file-input-${fieldName}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const fieldsData = [
    {
      fieldLabel: "Email address*",
      placeholder: "Enter your email address",
      fieldName: "email_address",
      isMandatory: true,
      type: "email",
      errorMsg: "Email address is required field.",
      isFilled: !!formData.email_address,
    },
    {
      fieldLabel: "First name*",
      placeholder: "Enter your first name here",
      fieldName: "first_name",
      isMandatory: true,
      type: "text",
      errorMsg: "First name is required field.",
      isFilled: !!formData.first_name,
    },
    {
      fieldLabel: "Last name*",
      placeholder: "Enter your last name here",
      fieldName: "last_name",
      isMandatory: true,
      type: "text",
      errorMsg: "Last name is required field.",
      isFilled: !!formData.last_name,
    },
    {
      fieldLabel: "Attach CV*",
      fieldName: "attach_cv_file",
      isMandatory: true,
      placeholder: "Drop here from desktop or simple click and attach",
      type: "file",
      accept: ".doc, .docx, .pdf, .rtf, .txt",
      errorMsg: "CV is required field.",
      isFilled: !!formData.attach_cv_file,
    },
    {
      fieldLabel: "Attach Cover Letter (if)",
      fieldName: "attach_cover_letter_file",
      placeholder: "Drop here from desktop or simple click and attach",
      type: "file",
      isMandatory: false,
      accept: ".doc, .docx, .pdf, .rtf, .txt",
      isFilled: !!formData.attach_cover_letter_file,
    },
  ];

  const isAlphabetical = (value: string) => /^[A-Za-z]+$/.test(value);

  const validateFieldName = (fieldName: string, value: string) => {
    if (fieldName === "first_name" || fieldName === 'last_name') {
      if (!isAlphabetical(value) || value.trim() === '') {
        setFieldErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: "Only accept alphabetical letters."
        }));
      } else {
        setFieldErrors(prevErrors => {
          const {[fieldName]: removed, ...rest} = prevErrors;
          return rest;
        });
      }
    }
  }

  let icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M14.2649 2.60898C12.0571 0.401172 8.46174 0.401172 6.25627 2.60898L0.139084 8.72148C0.0992406 8.76133 0.0781467 8.81523 0.0781467 8.87148C0.0781467 8.92773 0.0992406 8.98164 0.139084 9.02148L1.00393 9.88633C1.04346 9.92569 1.09697 9.94778 1.15276 9.94778C1.20854 9.94778 1.26205 9.92569 1.30158 9.88633L7.41877 3.77383C8.17815 3.01445 9.1883 2.59727 10.2617 2.59727C11.3352 2.59727 12.3453 3.01445 13.1024 3.77383C13.8617 4.5332 14.2789 5.54336 14.2789 6.61445C14.2789 7.68789 13.8617 8.6957 13.1024 9.45508L6.86799 15.6871L5.85783 16.6973C4.9133 17.6418 3.37815 17.6418 2.43362 16.6973C1.97658 16.2402 1.7258 15.6332 1.7258 14.9863C1.7258 14.3395 1.97658 13.7324 2.43362 13.2754L8.61877 7.09258C8.7758 6.93789 8.98205 6.85117 9.20236 6.85117H9.20471C9.42502 6.85117 9.62893 6.93789 9.78362 7.09258C9.94065 7.24961 10.025 7.45586 10.025 7.67617C10.025 7.89414 9.9383 8.10039 9.78362 8.25508L4.72815 13.3059C4.6883 13.3457 4.66721 13.3996 4.66721 13.4559C4.66721 13.5121 4.6883 13.566 4.72815 13.6059L5.59299 14.4707C5.63252 14.5101 5.68603 14.5322 5.74182 14.5322C5.7976 14.5322 5.85112 14.5101 5.89065 14.4707L10.9438 9.41758C11.4102 8.95117 11.6656 8.33242 11.6656 7.67383C11.6656 7.01523 11.4078 6.39414 10.9438 5.93008C9.98049 4.9668 8.41487 4.96914 7.45158 5.93008L6.85158 6.53242L1.26877 12.1129C0.889859 12.4896 0.589503 12.9377 0.38512 13.4314C0.180737 13.9251 0.0763961 14.4544 0.0781467 14.9887C0.0781467 16.0738 0.502365 17.0934 1.26877 17.8598C2.0633 18.652 3.10393 19.048 4.14455 19.048C5.18518 19.048 6.2258 18.652 7.01799 17.8598L14.2649 10.6176C15.3313 9.54883 15.9219 8.12617 15.9219 6.61445C15.9242 5.10039 15.3336 3.67773 14.2649 2.60898Z"
        fill="black"
      />
    </svg>
  );

  const handleClick = () => {
    const areAllFieldsFilled = checkFieldValidation(fieldsData, formData, isValidEmail)

    if (areAllFieldsFilled) {
      setShowErrors(false);
      onSubmit && onSubmit(formData)
    } else {
      setShowErrors(true);
    }

  }

  const isFormValid = checkFieldValidation(fieldsData, formData, isValidEmail);

  return (
    <div className={classes.root}>
      <Box className={classes.boxContainer}>

        {fieldsData && fieldsData.map((item: any, index: number) => {
          if (item.type === "text" || item.type === "email") {
            return (
              <Box className={classes.innerBox} key={`${index}index`}>
                <Typography
                  className={clsx(
                    classes.textStyle,
                    classes.headingStyle,
                  )}
                >
                  {item.fieldLabel}
                </Typography>
                <TextField
                  id="outlined-secondary"
                  variant="outlined"
                  data-test-id={item.fieldName}
                  required
                  onChange={(event: any) => {
                    setFormData({
                      ...formData,
                      [item.fieldName]: event.target.value
                    })
                    validateFieldName(item.fieldName, event.target.value)
                  }}
                  value={formData[item.fieldName as keyof FormData]}
                  placeholder={item.placeholder}
                  InputProps={{
                    classes: {
                      input: classes.textStyle,
                    },
                    style: { color: "#B1B3C3" },
                  }}
                  className={classes.textFieldStyle}
                />
                {fieldErrors[item.fieldName] &&
                    <div className={classes.errorMessage}>{fieldErrors[item.fieldName]}</div>}

                {showErrors && item.isMandatory && formData[item.fieldName as keyof FormData] === "" &&
                  <div className={classes.errorMessage} >
                    {item.errorMsg}
                  </div>
                }
                {item.type === "email" && item.isMandatory && formData[item.fieldName as keyof FormData] !== "" && !isValidEmail(formData[item.fieldName as keyof FormData]) && 
                  <div className={classes.errorMessage} >
                    Please enter a valid email address.
                  </div>
                }
                {item.type === "email" && resData && !resData.success && resData.errors &&
                    <div className={classes.errorMessage}>{resData.errors.email_address}</div>
                }
              </Box>
            );
          }
          if (item.type === "file") {
            const label = !item.isFilled ? "Drop here from desktop or simply click and attach" : formData[item.fieldName as keyof FormData]?.name;
            return (
              <Box className={classes.innerBox} key={`${index}index`}>
                <Typography
                  className={clsx(
                    classes.textStyle,
                    classes.headingStyle,
                  )}
                >
                  {item.fieldLabel}
                </Typography>
                <TextField
                  id={`${item.fieldName}-file-input`}
                  variant="outlined"
                  data-test-id={`${item.fieldName}_textfield`}
                  onDragEnter={(e) => handleDrag(e, item.fieldName, setDragableField)}
                  onDragLeave={(e) => handleDrag(e, item.fieldName, setDragableField)}
                  onDragOver={(e) => handleDrag(e, item.fieldName, setDragableField)}
                  onDrop={(e) => handleDrop(e, item.fieldName, setDragableField, setFormData, formData)}
                  onClick={() => handleIconClick(item.fieldName)}
                  placeholder={label}
                  value={formData[item.fieldName as keyof FormData]?.name}
                  InputProps={{
                    classes: {
                      input: classes.textStyle,
                    },

                    endAdornment: (
                      <InputAdornment position="end" style={{cursor: 'pointer'}}>
                        {icon}
                      </InputAdornment>
                    ),
                  }}
                  className={
                    item.fieldName === dragableField ?
                      clsx(classes.textFieldStyle, classes.dropZoneField)
                      :
                      clsx(classes.textFieldStyle, classes.textStyle2)
                  }
                />
                {showErrors && item.isMandatory && !formData[item.fieldName as keyof FormData] &&
                  <div className={classes.errorMessage} >
                    {item.errorMsg}
                  </div>
                }
                <input
                  data-test-id={`file_input_${item.fieldName}`}
                  type="file"
                  id={`file-input-${item.fieldName}`}
                  className={classes.fileInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, item.fieldName)}
                  accept=".doc, .docx, .pdf, .rtf, .txt"
                />
                <Typography className={classes.subParagraph}>
                  {item.accept}
                </Typography>
              </Box>
            );
          }
        })}

        <Box className={classes.innerBox3}>
          <FormControlLabel
            control={<GreenCheckbox
              checked={!!formData.terms_conditions_agree}
              onChange={() =>
                setFormData(
                  {
                    ...formData,
                    terms_conditions_agree: formData.terms_conditions_agree === 1 ? 0 : 1
                  }
                )
              }
            />}
            label={
              <Typography
                className={clsx(classes.textStyle, classes.headingStyle)}
                style={{ color: "#595959" }}
              >
                I agree to the <Link target="_blank" to="/terms-and-conditions" className={classes.textStyle2}> Terms & Conditions </Link> and confirm that I've reviewed the <Link target="_blank" to="/privacy-policy" className={classes.textStyle2}> Privacy Policy</Link>.
              </Typography>
            }
          />
          {showErrors && !formData.terms_conditions_agree &&
              <p className={classes.errorMessage}>{configJSON.privacyPolicyErrorMessage}</p>}
        </Box>
        <Button
          onClick={handleClick}
          className={isFormValid ? classes.customButton : classes.disabledCustomButton}
          data-test-id="next-button">
          NEXT
        </Button>

      </Box>
    </div>
  );
}

export default JobsForm;