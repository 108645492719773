import React from "react";
import DynamicContentController, { Props } from "../DynamicContentController";
import JobsForm from "./JobsForm.web";
import {
  Box
} from "@material-ui/core";
import Heading from "../../../../components/src/FreeAssessmentPage/Heading";

class JobApplicationPage extends DynamicContentController {
  constructor(props: Props) {
    super(props);
  }

  handlePositionProcess = (query?: string) => {
    if (!query) {
      return '';
    }
    return query.split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  render() {
    const position = this.handlePositionProcess(window.location.pathname.split('/').pop());

    return <>
      <Box style={{ marginTop: "50px" }}>
        <Heading
          tag={position}
          heading={"Job Application"}
        />
        <JobsForm
          data-test-id="jobs-forms"
          resData={this.state.jobApplicationData}
          position={position}
          onSubmit={(res: any) => this.getFormApplicationData(res)}
        />
      </Box>
    </>
  }
}
export default JobApplicationPage;