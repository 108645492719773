import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Header, EmissionData } from "../../cfnzdpuscope2emissions/src/Cfnzdpuscope2emissionsController";

interface Category {
  "Emissions": EmissionData[];
  "Relevancy and rationale if not disclosed": EmissionData[];
  "Methodology 1": EmissionData[];
  "Exclusion 1": EmissionData[];
  "Change in Scope 3 Emissions calculation or reporting boundary": EmissionData[];
}

const initialCategory = {
  "Emissions": [],
  "Relevancy and rationale if not disclosed": [],
  "Methodology 1": [],
  "Exclusion 1": [],
  "Change in Scope 3 Emissions calculation or reporting boundary": [],
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  value?: number;
  index?: number;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  emissionDetailsId: number;
  openBreakdown: boolean;
  openTotalScope3Emissions: boolean;
  openCategory1: boolean;
  openCategory2: boolean;
  openCategory3: boolean;
  openCategory4: boolean;
  openCategory5: boolean;
  openCategory6: boolean;
  openCategory7: boolean;
  openCategory8: boolean;
  openCategory9: boolean;
  openCategory10: boolean;
  openCategory11: boolean;
  openCategory12: boolean;
  openCategory13: boolean;
  openCategory14: boolean;
  openCategory15: boolean;
  openOtherScope3Emissions: boolean;
  openIndirectGHGEmissionsFromTransportation: boolean;
  openIndirectGHGEmissionsAssociated: boolean;
  openIndirectGHGEmissionsFromProduct: boolean;
  openIndirectGHGEmissionsFromOtherSources: boolean;
  breakdown: EmissionData[];
  totalScope3Emissions: EmissionData[];
  category1: Category,
  category2: Category,
  category3: Category,
  category4: Category,
  category5: Category,
  category6: Category,
  category7: Category,
  category8: Category,
  category9: Category,
  category10: Category,
  category11: Category,
  category12: Category,
  category13: Category,
  category14: Category,
  category15: Category,
  otherScope3Emissions: Category,
  indirectGHGEmissionsFromTransportation: Category,
  indirectGHGEmissionsAssociated: Category,
  indirectGHGEmissionsFromProducts: Category,
  indirectGHGEmissionsFromOtherSources: Category,
  [key: string]: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfnzdpuscope3emissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScope3EmissionDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      emissionDetailsId: 0,
      openBreakdown: true,
      openTotalScope3Emissions: true,
      openCategory1: true,
      openCategory2: false,
      openCategory3: false,
      openCategory4: false,
      openCategory5: false,
      openCategory6: false,
      openCategory7: false,
      openCategory8: false,
      openCategory9: false,
      openCategory10: false,
      openCategory11: false,
      openCategory12: false,
      openCategory13: false,
      openCategory14: false,
      openCategory15: false,
      openOtherScope3Emissions: false,
      openIndirectGHGEmissionsFromTransportation: false,
      openIndirectGHGEmissionsAssociated: false,
      openIndirectGHGEmissionsFromProduct: false,
      openIndirectGHGEmissionsFromOtherSources: false,
      breakdown: [],
      totalScope3Emissions: [],
      category1: initialCategory,
      category2: initialCategory,
      category3: initialCategory,
      category4: initialCategory,
      category5: initialCategory,
      category6: initialCategory,
      category7: initialCategory,
      category8: initialCategory,
      category9: initialCategory,
      category10: initialCategory,
      category11: initialCategory,
      category12: initialCategory,
      category13: initialCategory,
      category14: initialCategory,
      category15: initialCategory,
      otherScope3Emissions: initialCategory,
      indirectGHGEmissionsFromTransportation: initialCategory,
      indirectGHGEmissionsAssociated: initialCategory,
      indirectGHGEmissionsFromProducts: initialCategory,
      indirectGHGEmissionsFromOtherSources: initialCategory,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Receive emission details id from state after navigation
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );

      this.setState({ emissionDetailsId: data.emissionDetailsId });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.getScope3EmissionDetailsApiCallId) {
        this.handleScope3EmissionDetailsResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  componentDidUpdate(
    prevProps: Readonly<Props>, 
    prevState: Readonly<S>, 
    snapshot?: SS | undefined
  ): void {
    if (prevState.emissionDetailsId !== this.state.emissionDetailsId && this.state.emissionDetailsId > 0) {
      // Call api to get details of scope 3 emission details when we receive a new valid emission details id
      this.getScope3EmissionDetails(this.state.emissionDetailsId);
    }
  }

  // Common method to call apis
  apiCall = async (data: any) => {
    const { method, endPoint, payload, contentType } = data;
    const token = localStorage.getItem("token") ?? "";
    const header: Header = { token };
  
    if (contentType) {
      header["Content-Type"] = contentType;
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
  
    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Get details for scope 1 emissions
  getScope3EmissionDetails = async (emissionDetailsId: number) => {
    this.getScope3EmissionDetailsApiCallId = await this.apiCall({
      contentType: configJSON.apiContentTypeApplicationJson,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.scope3EmisstionDetailsAPiEndPoint,
      payload: JSON.stringify({ metadata_id: emissionDetailsId }),
    }); 
  }

  // Handle scope 2 emission details response
  handleScope3EmissionDetailsResponse = (response: any) => {
    if (response.scope3_list) {
      this.setState({
        breakdown: response.scope3_list["Breakdown"],
        totalScope3Emissions: response.scope3_list["Total Scope 3 Emissions"],
        category1: response.scope3_list["Category 1 (Purchased goods and services)"],
        category2: response.scope3_list["Category 2 (Capital goods)"],
        category3: response.scope3_list["Category 3 (Fuel- and energy-related activities not included in Scope 1 or Scope 2)"],
        category4: response.scope3_list["Category 4 (Upstream transportation and distribution)"],
        category5: response.scope3_list["Category 5 (Waste generated in operations)"],
        category6: response.scope3_list["Category 6 (Business travel)"],
        category7: response.scope3_list["Category 7 (Employee commuting)"],
        category8: response.scope3_list["Category 8 (Upstream leased assets)"],
        category9: response.scope3_list["Category 9 (Downstream transportation and distribution)"],
        category10: response.scope3_list["Category 10 (Processing of sold products)"],
        category11: response.scope3_list["Category 11 (Use of sold products)"],
        category12: response.scope3_list["Category 12 (End-of-life treatment of sold products)"],
        category13: response.scope3_list["Category 13 (Downstream leased assets)"],
        category14: response.scope3_list["Category 14 (Franchises)"],
        category15: response.scope3_list["Category 15 (Investments)"],
        otherScope3Emissions: response.scope3_list["Other Scope 3 emissions"],
        indirectGHGEmissionsFromTransportation: response.scope3_list["Indirect GHG Emissions from transportation"],
        indirectGHGEmissionsAssociated: response.scope3_list["Indirect GHG emissions associated with the use of products from the organization"],
        indirectGHGEmissionsFromProducts: response.scope3_list["Indirect GHG emissions from products used by organization"],
        indirectGHGEmissionsFromOtherSources: response.scope3_list["Indirect GHG emissions from other sources"],
      });
    }
  };
  
  // Toggle collapses
  handleToggleCollaplse = (key: string) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        [key]: !prevState[key]
      }
    })
  }
  
  // Customizable Area End
}
