import React, { useState } from "react";
import {
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
  Box
} from "@material-ui/core";

export const DropdownIcon = (props: any) => {
  return (
    <svg
      style={{ marginRight: "12px" }}
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.5L10 12.5L5 7.5"
        stroke="#7D7D7F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const FieldLabelStyled = styled(Typography)({
  fontSize: "15px",
  fontFamily: "Lato",
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "8px"
});

const FieldGroupStyled = styled(Box)({
  marginBottom: "40px"
});

const SelectDropdownStyled = styled(Select)({
  borderRadius: "8px",
  height: "48px",
  padding: "0px!important;",
  "& *": {
    background: "#fff !important;",
    fontWeight: 400,
    fontFamily: "Lato",
    fontSize: "15px !important",
    lineHeight: "24px"
  },
  "&:focus": {
    borderColor: "#cccccc"
  }
});

interface DropdownFormControlProps {
  noFixedWidth?: boolean;
}

export const DropdownFormControl = styled(FormControl)(({ noFixedWidth }: DropdownFormControlProps) => {
  return {
    borderRadius: "8px",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    '@media(max-width: 700px)': {
      width: noFixedWidth ? "100%" : "63%"
    },
  }
});

const MenuItemStyled = styled(MenuItem)({
  whiteSpace: "normal",
  maxWidth: "100%"
});

const ErrorAlert = styled("div")({
  color: "#ff0000",
  lineHeight: 1,
  marginTop: "10px",
  fontFamily: "Lato",
  fontSize: "15px"
});

const useStyles = makeStyles({
  textFieldStyle: {
    "& *": {
      borderRadius: "8px !important",
      borderColor: "#D9D9D9 !important"
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px"
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px"
    },
    width: "100%",
    height: "48px"
  },
  textStyle: {
    lineHeight: "24px",
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Lato",
    borderRadius: "8px",
    color: "#34373A",
    height: "48px",
    boxSizing: "border-box",
    padding: "0 15px"
  },
  muiSelectSelect: {
    padding: "8px 15px",
    "& *": {
      fontSize: "15px !important"
    }
  }
});

interface SelectFieldStyledPropsType {
  onChange: any;
  value: string | number;
  options: {
    id: number;
    value: number | string;
    label: string;
  }[];
  label: string;
  placeholder?: string;
  defaultSelectLabel?: string;
  fullwidth?: string;
  noFixedWidth?: boolean;
  noMarginBottom?: boolean;
  error?: boolean;
  errorMessage?: string;
  name?: string;
}

const SelectFieldStyled: React.FC<SelectFieldStyledPropsType> = ({
  name,
  onChange,
  value,
  options,
  label,
  placeholder,
  defaultSelectLabel,
  fullwidth,
  error,
  errorMessage,
  noFixedWidth,
  noMarginBottom,
}) => {
  const classes = useStyles();

  const [selectOpen, setSelectOpen] = useState(false);

  const handleOnOpen = () => {
    setSelectOpen(!selectOpen);
  };

  const ddwidth = fullwidth || "404px";

  return (
    <FieldGroupStyled 
      style={{ 
        width: noFixedWidth ? "auto" : ddwidth,
        marginBottom: noMarginBottom ? "0px" : "40px"
      }}>
      <FieldLabelStyled>{label}</FieldLabelStyled>
      <DropdownFormControl noFixedWidth={noFixedWidth}>
        <SelectDropdownStyled
          name={name}
          data-test-id="styled_select"
          onOpen={() => handleOnOpen()}
          placeholder={placeholder}
          displayEmpty
          classes={{ root: classes.muiSelectSelect }}
          IconComponent={DropdownIcon}
          value={value}
          onChange={onChange}
          disableUnderline
          MenuProps={{
            PaperProps: {
              style: {
                width: noFixedWidth ? "min-content" : ddwidth,
                maxHeight: 200,
              }
            },
            anchorOrigin: {
              horizontal: "left",
              vertical: "bottom"
            },
            transformOrigin: {
              horizontal: "left",
              vertical: "top"
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem disabled value="">
            <span style={{ opacity: "0.45" }}>
              {selectOpen ? "Select" : defaultSelectLabel}
            </span>
          </MenuItem>
          {options &&
            options.map((option) => (
              <MenuItemStyled key={option.id} value={option.value}>
                <span style={{ fontSize: "15px" }}> {option.label}</span>
              </MenuItemStyled>
            ))}
        </SelectDropdownStyled>
      </DropdownFormControl>
      {error && errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert>}
    </FieldGroupStyled>
  );
};

export default SelectFieldStyled;
