import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  InputLabel,
  TextField,
  FormControlLabel
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import CustomSnackbar from "../../dashboard/src/common/CustomSnackbar.web";
import { ArrowUpward } from '@material-ui/icons';
import { checkIcon, uncheckIcon } from "./assets";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End
export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Required for all blocks
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Formik
          initialValues={this.state.initialValue}
          validationSchema={this.state.signupSchema}
          onSubmit={values => this.handleLogin(values)}
        >
          {formik => {
            const {
              values,
              touched,
              handleChange,
              handleSubmit,
              errors,
              handleBlur
            } = formik;
            return (

              <form
                onSubmit={handleSubmit}
                className="form-one"
                data-test-id="accountLogin"
                name="accountLogin"
              >
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="email" style={webStyle.labelInput}>
                      Username or Email
                    </InputLabel>
                    <TextField
                      id="email"
                      name="email"
                      placeholder="Enter your username or password"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        disableUnderline: true
                      }}
                      margin="normal"
                      onChange={handleChange}
                      value={values.email}
                      style={{
                        ...webStyle.textFieldBorder,
                        border: touched.email && errors.email ? "#FFA39E solid 1px" : "#CBD5E1 solid 1px",
                      }}
                      onBlur={handleBlur}
                      data-test-id="txtInputEmail"
                    />
                    {touched.email && errors.email ? (
                      <div style={webStyle.red}>{errors.email}</div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel htmlFor="password" style={webStyle.labelInput}>
                      Password
                    </InputLabel>
                    <TextField
                      id="password"
                      name="password"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      placeholder="Enter password"
                      fullWidth
                      variant="standard"
                      margin="normal"
                      data-test-id="txtInputPassword"
                      onChange={handleChange}
                      value={values.password}
                      style={{
                        ...webStyle.textFieldBorder,
                        border: touched.password && errors.password ? "#FFA39E solid 1px" : "#CBD5E1 solid 1px",
                      }}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                              name="eye"
                            >
                              {this.state.enablePasswordField ? (
                                <VisibilityOutlined style={webStyle.eyeIcon} />
                              ) : (
                                <VisibilityOffOutlined style={webStyle.eyeIcon} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        disableUnderline: true
                      }}
                    />
                    {errors.password ? (
                      <div style={webStyle.red}>{errors.password}</div>
                    ) : null}
                    {this.state.error ? (
                      <div style={webStyle.red}>{this.state.error}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', maxWidth: '404px' }}>
                      <FormControlLabel
                        label={<span style={webStyle.rememberMeLabel}>Remember me</span>}
                        style={{ color: '#34373A' }}
                        control={
                          <Checkbox 
                            disableRipple
                            icon={<img src={uncheckIcon} style={{ width: "20px", height: "20px" }} />}
                            checkedIcon={<img src={checkIcon} style={{ width: "20px", height: "20px" }} />}
                            name="rememberme" 
                            onChange={handleChange} 
                            checked={values.rememberme} 
                            color="default" 
                            data-test-id="btnRememberMe" 
                          />
                        }
                      />
                      <Link to="/forgot-password" className="forgot"
                            style={webStyle.forgotPasswordLink}
                            data-test-id="btnForgotPassword">Forgot Password?</Link>
                    </Box>
                  </Grid>
                </Grid>
                <div style={webStyle.ButtonStylelogin}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: this.isGoNext(errors, values),
                      borderRadius: "8px",
                      width: "184px",
                      height: "56px",
                      color: "black"
                    }}
                    type="submit"
                    data-test-id="btnSocialLogin"
                    endIcon={<ArrowUpward style={webStyle.icon} />}
                  >
                    LOGIN
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
        <CustomSnackbar
            dataTestId="alert_snackbar"
            alert
            open={this.state.alert.isOpen}
            onClose={this.handleCloseCustomSnackbar}
            status="success"
            AlertLabel={this.state.alert.message}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  containerStyle: {
    paddingTop: "75px"
  },
  form: {
    maxWidth: "500px",
    margin: "20px auto"
  },
  labelInput: {
    color: "#34373A",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center"
  },
  textFieldBorder: {
    border: "#D9D9D9 solid 1px",
    borderRadius: "8px",
    padding: "8.5px 14px",
    font: "inherit",
    height: "48px",
    maxWidth: '404px'
  },
  red: {
    color: "#F5222D",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px"
  },
  ButtonStylelogin: {
    display: "flex",
    justifyContent: "center",
    marginTop: "70px",
    maxWidth: '404px'
  },
  button: {
    backgroundColor: "#43D270",
    borderRadius: "8px",
    width: "184px",
    height: "56px",
    color: "black"
  },
  eyeIcon: {
    color: '#B1B3C3'
  },
  icon: {
    rotate: "45deg",
    marginLeft: "5px",
    color: 'black'
  },
  rememberMeLabel: {
    fontFamily: "Lato",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#34373A"
  },
  forgotPasswordLink: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22.4px",
    color: "#43D270"
  }
};
// Customizable Area End
