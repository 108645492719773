import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";

interface Goal {
  id: number;
  goal_id: number;
  goal_name?: string;
  goal_title?: string;
  goal_description?: string;
  goal_icon?: string;
  goal_green_icon?: string;
  goal_enabled: boolean;
}

interface GetUNSDGPayload {
  account_id: number;
  company_id?: number;
}

interface SaveUNSDGPayload {
  account_id: number;
  unsdg_goals: Goal[];
  company_id?: number;
}

interface UNSDGGoalsResponse {
  unsdg_goals: Goal[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showSelectedGoals?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  unsdgGoals: Goal[];
  backupUnsdgGoals: Goal[];
  goalChangeDetected: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfunsdgsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUNSDGGoalsApiCallId: string = "";
  getSavedUNSDGGoalsApiCallId: string = "";
  saveUNSDGGoalsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      backupUnsdgGoals: [],
      unsdgGoals: [],
      goalChangeDetected: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.getUNSDGGoalsApiCallId) {
        this.handleUNSDGGoalsResponse(responseJson);
      } else if (apiRequestCallId === this.getSavedUNSDGGoalsApiCallId) {
        this.handleUNSDGGoalsResponse(responseJson);
      } else if (apiRequestCallId === this.saveUNSDGGoalsApiCallId) {
        this.handleSaveUNSDGGoalsResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.props.showSelectedGoals 
      ? await this.getSavedUNSDGGoals()
      : await this.getUNSDGGoals();  
  }

  componentDidUpdate(
    prevProps: Readonly<Props>, 
    prevState: Readonly<S>, 
    snapshot?: SS | undefined
  ): void {
    // Compare unsdgGoals with backup goals to detect goal changes
    const goalChangeDetected = this.state.unsdgGoals.some((goal, index) => {
      const backupGoal = this.state.backupUnsdgGoals[index];
      return goal.goal_enabled !== backupGoal.goal_enabled;
    });
    if (goalChangeDetected !== this.state.goalChangeDetected) {
      this.setState({ goalChangeDetected });
    }
  }

  // Get UNSDGs 
  getUNSDGGoals = async () => {
    this.setState({ isLoading: true });
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
    const payload: GetUNSDGPayload = { 
      account_id: userDetails.meta.id
    };

    if (userDetails.meta.user_account_type === "portfolio_manager") {
      payload.company_id = orgDetails.id;
    }

    this.getUNSDGGoalsApiCallId = await apiCall({
      contentType: configJSON.apiContentTypeApplicationJson,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getUNSDGGoalsAPiEndPoint,
      payload: JSON.stringify(payload),
    }); 
  }

  // Handle scope 2 emission details response
  handleUNSDGGoalsResponse = (response: UNSDGGoalsResponse) => {
    if (response.unsdg_goals) {
      this.setState({
        isLoading: false,
        unsdgGoals: response.unsdg_goals,
        backupUnsdgGoals: response.unsdg_goals,
      });
    }
  };

  // Handle goal selection
  handleGoalSelection = (selectedGoalId: number) => {
    // Find the goal using selected goal id & toggle it's goal_enabled field
    const selectedGoalIndex = this.state.unsdgGoals.findIndex((goal) => goal.id === selectedGoalId);
    const updatedUnsdgGoals = JSON.parse(JSON.stringify(this.state.unsdgGoals));
    updatedUnsdgGoals[selectedGoalIndex].goal_enabled = !updatedUnsdgGoals[selectedGoalIndex].goal_enabled;
    this.setState({
      ...this.state,
      unsdgGoals: updatedUnsdgGoals
    });
  }

  // Save UNSDG Goals 
  saveUNSDGGoals = async () => {
    this.setState({ isLoading: true });
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");

    const goals = this.state.unsdgGoals.map((goal) => {
      return {
        id: goal.id,
        goal_id: goal.goal_id,
        goal_enabled: goal.goal_enabled
      }
    });

    const payload: SaveUNSDGPayload = {
      account_id: userDetails.meta.id,
      unsdg_goals: goals,
    }

    if (userDetails.meta.user_account_type === "portfolio_manager") {
      payload.company_id = orgDetails.id;
    }

    this.saveUNSDGGoalsApiCallId = await apiCall({
      contentType: configJSON.apiContentTypeApplicationJson,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.saveUNSDGGoalsAPiEndPoint,
      payload: JSON.stringify(payload),
    }); 
  }

  // Handle save UN SDG Goals response
  handleSaveUNSDGGoalsResponse = (response: { success: boolean }) => {
    if (response.success && !this.props.showSelectedGoals) {
      this.setState({ 
        isLoading: false, 
        backupUnsdgGoals: this.state.unsdgGoals 
      });
    } else if (response.success && this.props.showSelectedGoals) {
      const savedGoals = this.state.unsdgGoals.filter(goal => goal.goal_enabled);
      this.setState({
        isLoading: false,
        unsdgGoals: savedGoals,
        backupUnsdgGoals: savedGoals,
      });
    }
  }

  // Get saved UNSDGs 
  getSavedUNSDGGoals = async () => {
    this.setState({ isLoading: true });
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");
    const payload: GetUNSDGPayload = { 
      account_id: userDetails.meta.id
    };

    if (userDetails.meta.user_account_type === "portfolio_manager") {
      payload.company_id = orgDetails.id;
    }

    this.getSavedUNSDGGoalsApiCallId = await apiCall({
      contentType: configJSON.apiContentTypeApplicationJson,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getSavedUNSDGGoalsAPiEndPoint,
      payload: JSON.stringify(payload),
    }); 
  }

  // Customizable Area End
}
