import React from "react";
import {
  styled,
  FormControl,
  TextField,
  Grid,
  Typography as TypographyMUI,
} from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import SelectFieldStyled from "../../../dashboard/src/common/SelectFieldStyled.web";

interface Option {
  id: number;
  value: string;
  label: string;
}

interface Props {
  createLabel: (paramName: string) => string;
  createOptions: (paramName: string) => Option[]
}

interface Target {
  name?: string;
  value: string;
}

interface Input {
  type: string;
  name: string;
  value: string;
}

interface Inputs {
  origin: Input;
  destination: Input;
  weight: Input;
  weight_unit: Input;
  transport_mode: Input;
}

interface InputRow {
  id: string;
  inputs: Inputs;
}

interface InputRowsType {
  inputRows: InputRow[];
}


export default function InputRows(props: Props) {
  const { values, handleChange, touched, errors, setFieldValue, setValues } = useFormikContext<InputRowsType>();

  const handleDropdownChange = (
    event: React.ChangeEvent<Target>,
    name: string,
    label: string,
    rowIndex: number,
  ) => {
    if (label === "Transport Mode") {
      const { value } = event.target;
      const currentValues = JSON.parse(JSON.stringify(values));
      switch (value) {
        case "rail":
          // Remove air options
          delete currentValues.inputRows[rowIndex].inputs["aircraft_type"];
          delete currentValues.inputRows[rowIndex].inputs["methodology"];

          currentValues.inputRows[rowIndex].inputs["transport_mode"] = { type: "dropdown", name: "transport_mode", value };
          currentValues.inputRows[rowIndex].inputs["fuel_type"] = { type: "dropdown", name: "fuel_type", value: "" };
          currentValues.inputRows[rowIndex].inputs["load_type"] = { type: "dropdown", name: "load_type", value: "" };
          setValues(currentValues);
          break;
        case "air":
          // Remove rail options
          delete currentValues.inputRows[rowIndex].inputs["fuel_type"]
          delete currentValues.inputRows[rowIndex].inputs["load_type"]

          currentValues.inputRows[rowIndex].inputs["transport_mode"] = { type: "dropdown", name: "transport_mode", value };
          currentValues.inputRows[rowIndex].inputs["aircraft_type"] = { type: "dropdown", name: "aircraft_type", value: "" };
          currentValues.inputRows[rowIndex].inputs["methodology"] = { type: "dropdown", name: "methodology", value: "" };
          setValues(currentValues);
          break;

        default:
          break;
      }
    } else {
      setFieldValue(name, event.target.value);
    }
  }

  return (
    <>
      {values.inputRows.length > 0 && values.inputRows.map((inputRow, rowIndex) => {
        return (
          <FieldContainer>
            <Grid
              container
              spacing={2}
              alignItems="baseline"
              key={inputRow.id}
            >
              {
                Object.values(inputRow.inputs).map((input) => {
                  const label = props.createLabel(input.name);
                  const options = props.createOptions(input.name);
                  const name = `inputRows[${rowIndex}].inputs[${input.name}].value`;
                  if (input.type === "dropdown") {
                    return (
                      <Grid key={input.name} item xs={12} md={3}>
                        <SelectFieldStyled
                          noFixedWidth
                          name={name}
                          value={input.value}
                          onChange={(event: React.ChangeEvent<Target>) => handleDropdownChange(event, name, label, rowIndex)}
                          options={options}
                          label={label}
                          placeholder="Select from list"
                          defaultSelectLabel="Select from list"
                          error={
                            getIn(touched, name) &&
                            getIn(errors, name)
                          }
                          errorMessage={
                            getIn(touched, name) &&
                            getIn(errors, name)
                          }
                        />
                      </Grid>
                    );
                  } else if (input.type === "text") {
                    return (
                      <Grid key={input.name} item xs={12} md={3}>
                        <LabelStyled>Weight</LabelStyled>
                        <TextInput
                          id="outlined-basic"
                          variant="outlined"
                          name={name}
                          value={input.value}
                          onChange={handleChange}
                          placeholder="Write weight"
                          error={
                            getIn(touched, name) &&
                            getIn(errors, name)
                          }
                          helperText={
                            getIn(touched, name) &&
                            getIn(errors, name)
                          }
                          inputProps={{
                            style: { fontSize: "15px" }
                          }}
                          InputProps={{
                            style: {
                              height: "50px",
                              borderRadius: "8px",
                            }
                          }}
                          FormHelperTextProps={{
                            style: {
                              color: "#ff0000",
                              lineHeight: 1,
                              margin: "10px 0 0 0",
                              fontFamily: "Lato",
                              fontSize: "15px"
                            }
                          }}
                        />
                      </Grid>
                    );
                  }
                })
              }
            </Grid>
          </FieldContainer>
        )
      }
      )}
    </>
  );
}

const TextInput = styled(TextField)({
  width: '100%',
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  marginBottom: 40,
  '& *': {
    borderColor: "rgba(0, 0, 0, 0.2) !important",
  },
});

const LabelStyled = styled(TypographyMUI)({
  fontSize: "15px",
  fontFamily: "Lato",
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "8px"
});

const FieldContainer = styled(FormControl)({
  width: "100%",
  borderBottom: "1px solid #EBEBEB",
  marginBottom: "25px"
});