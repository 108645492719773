import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { v4 as uuidv4 } from 'uuid';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";

interface CardDetails {
  taskId: number;
  materialFactorId: number | null;
  categoryId: number;
  coreFactorId: number;
  cardId: number;
  status: string;
}

interface Question {
  id: number;
  answer: string;
  question_type: string;
}

interface ShippingFreightResponse {
  success: boolean;
  data: {
    card_title: string;
    card_description: string;
    questions: Question[];
  }
}

interface Input {
  type: string;
  name: string;
  value: string;
}

interface Inputs {
  origin: Input;
  destination: Input;
  weight: Input;
  weight_unit: Input;
  transport_mode: Input;
}

interface InputRow {
  id: string;
  inputs: Inputs;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  cardId: number;
  taskId: number;
  pageTitle: string;
  pageDescription: string;
  questionId: number;
  questionType: string;
  co2Result: string;
  inputRows: InputRow[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfclimatiqshippingfreightController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getShipFreightApiCallId: string = "";
  initialRow = {
    id: uuidv4(),
    inputs: {
      origin: {type: "dropdown", name: "origin", value: ""},
      destination: {type: "dropdown", name: "destination", value: ""},
      weight: {type: "text", name: "weight", value: ""},
      weight_unit: {type: "dropdown", name: "weight_unit", value: ""},
      transport_mode: {type: "dropdown", name: "transport_mode", value: ""},
    }
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      cardId: -1,
      taskId: -1,
      pageTitle: "",
      pageDescription: "",
      questionId: -1,
      questionType: "",
      co2Result: "",
      inputRows: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Handle api responses
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.getShipFreightApiCallId) {
        this.handleShippingFreightDetails(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const cardDetails = await getStorageData("shipFreightPayload");
    const cardDetailsJson = JSON.parse(cardDetails);
    this.getShippingFreightDetails(cardDetailsJson);
  }


  // Get energy consumption details
  getShippingFreightDetails = async (cardDetails: CardDetails) => {
    this.setState({ 
      isLoading: true,
      cardId: cardDetails.cardId,
      taskId: cardDetails.taskId,
    });

    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    const payload = { 
      account_id: userDetails.meta.id,
      card_id: cardDetails.cardId,
      category_id: cardDetails.categoryId,
      core_factor_id: cardDetails.coreFactorId,
      material_factor_id: cardDetails.materialFactorId,
      task_id: cardDetails.taskId
    };

    this.getShipFreightApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getShippingFreightDetailsAPiEndPoint,
      payload: JSON.stringify(payload),
    }); 
  }

  handleShippingFreightDetails = (response: ShippingFreightResponse) => {
    if (!response.success) {
      return;
    }

    this.setState({
      isLoading: false,
      questionId: response.data.questions[0].id,
      questionType: response.data.questions[0].question_type,
      co2Result: response.data.questions[0].answer,
      pageTitle: response.data.card_title,
      pageDescription: response.data.card_description,
    });

    this.setState({ 
      inputRows: [
        ...this.state.inputRows,
        this.initialRow,
      ]  
    });
  }

  generateLabelFromParamName = (paramName: string): string => {
    switch (paramName) {
      case "origin":
        return "Origin";
      case "destination":
        return "Destination";
      case "weight":
        return "Weight";
      case "weight_unit":
        return "Weight Unit";
      case "transport_mode":
        return "Transport Mode";
      case "aircraft_type":
        return "Aircraft Type";
      case "methodology":
        return "Methodology";
      case "fuel_type":
        return "Fuel Type";
      case "load_type":
        return "Load Type";

      default:
        return "";
    }
  }

  generateOptionsFromParamName = (paramName: string) => {
    const options = [
      {id: 1, label: "Option 1", value: "option1"},
      {id: 2, label: "Option 2", value: "option2"},
    ];
    const countries = [
      {id: 1, label: "United Arab Emirates", value: "uae"},
      {id: 2, label: "Singapore", value: "singapore"},
      {id: 3, label: "Malaysia", value: "malaysia"},
    ];
    const transportModes = [
      {id: 1, label: "Rail", value: "rail"},
      {id: 2, label: "Air", value: "air"},
    ];
    const fuelTypes = [
      {id: 1, label: "Fuel type 1", value: "Fuel type 1"},
      {id: 3, label: "Fuel type 2", value: "Fuel type 2"},
    ];
    const loadTypes = [
      {id: 1, label: "Load type 1", value: "load type 1"},
      {id: 2, label: "Load type 2", value: "load type 2"},
    ];
    const aircraftTypes = [
      {id: 1, label: "Aircraft type 1", value: "aircraft type 1"},
      {id: 2, label: "Aircraft type 2", value: "aircraft type 2"},
    ];
    const methodologies = [
      {id: 1, label: "Methodology 1", value: "methodology 1"},
      {id: 2, label: "Methodology 2", value: "methodology 2"},
    ];

    switch (paramName) {
      case "origin":
        return countries;
      case "destination":
        return countries;
      case "weight_unit":
        return options;
      case "transport_mode":
        return transportModes;
      case "fuel_type":
        return fuelTypes;
      case "load_type":
        return loadTypes;
      case "aircraft_type":
        return aircraftTypes;
      case "methodology":
        return methodologies;
    
      default:
        return [];
    }
  }
  // Customizable Area End
}
