import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  styled,
  Grid,
  TextField,
  TextFieldProps,
  Button,
  Typography,
} from "@material-ui/core";
import { Formik, Form, getIn } from "formik";
import * as yup from "yup";
import clsx from "clsx";
import SelectFieldStyled from "../../../dashboard/src/common/SelectFieldStyled.web";
import { logo, pen } from "../assets";

const validationSchema = yup.object({
  origin: yup.string().required("Origin is required"),
  destination: yup.string().required("Destination is required"),
  weight: yup.number().required("Weight is required").typeError("Weight must be a number"),
  weightUnit: yup.string().required("Weight unit is required"),
  mode: yup.string().required("Mode is required"),
});

const TextInput = styled(TextField)({
  width: '100%',
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  marginTop: "10px",
  '& *': {
    borderColor: "rgba(0, 0, 0, 0.2) !important",
  },
});

function CustomTextField(props: TextFieldProps) {
  return (
    <TextInput
      {...props}
      variant="outlined"
      data-test-id={props.name}
      inputProps={{
        style: { 
          fontSize: "15px",
          color: "#34373A",
          lineHeight: 1,
          fontFamily: "Lato",
        }
      }}
      InputProps={{
        style: {
          height: "50px",
          borderRadius: "8px",
        }
      }}
      FormHelperTextProps={{
        style: {
          color: "#ff0000",
          lineHeight: 1,
          margin: "10px 0 0 0",
          fontFamily: "Lato",
          fontSize: "15px"
        }
      }}
    />
  );
}

interface FormData {
  origin: string;
  destination: string;
  weight: string;
  weightUnit: string;
  mode: string;
}

interface Props {
  showFormInfoCard: boolean;
  onEditClick: () => void;
  onSubmit: (formData: FormData) => void;
}

export default function IntermodalForm(props: Props) {
  const classes = useStyles();
  const weightUnitOptions = [
    { id: 1, value: "g", label: "G" },
    { id: 2, value: "kg", label: "Kg" },
    { id: 3, value: "t", label: "T" },
    { id: 4, value: "lb", label: "Lb" },
    { id: 5, value: "ton", label: "Ton" },
  ];
  const transportModeOptions = [
    { id: 1, value: "air", label: "Air" },
    { id: 2, value: "road", label: "Road" },
    { id: 3, value: "sea", label: "Sea" },
    { id: 4, value: "rail", label: "Rail" },
  ];
  const initialValues = {
    origin: "",
    destination: "",
    weight: "",
    weightUnit: "",
    mode: "",
  };

  return (
    <>
      <div 
        data-test-id="logo"
        className={classes.logoContainer} 
        onClick={() => window.open("/", "_self")}
      >
        <img className={classes.logo} src={logo} alt="logo" width={144} height={22} />
      </div>
      <div className={classes.container}>
        <div 
          className={classes.titleDescriptionContainer}
          style={{ display: props.showFormInfoCard ? "none" : "block" }}
        >
          <Typography className={classes.title}>Try our interactive demo</Typography>
          <Typography
            className={classes.description}
          >
            Select your shipment locations and transport modes and our calculation engine will handle the rest.
          </Typography>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => props.onSubmit(values)}
        >
          {(formikProps) => {
            if (props.showFormInfoCard) {
              return (
                <div className={classes.card}>
                  <div className={classes.cardTitleContainer}>
                    <Typography 
                      data-test-id="info-title" 
                      className={classes.cardTitle}
                    >
                      Freight Details
                    </Typography>
                    <Button
                      type="button"
                      className={classes.editBtn} 
                      onClick={props.onEditClick}
                      startIcon={<img src={pen} alt="pen" width={11} height={11} />}
                    >
                      Edit
                    </Button>
                  </div>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography className={classes.formInfo}>
                        <span className={classes.formInfoBold}>Orgin : </span>
                        {formikProps.values.origin}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography 
                        className={`${classes.formInfo}`}
                      >
                        <span className={classes.formInfoBold}>Destination : </span>
                        {formikProps.values.destination}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography className={classes.formInfo}>
                        <span className={classes.formInfoBold}>Weight : </span>
                        {formikProps.values.weight}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography 
                        className={`${classes.formInfo}`}
                      >
                        <span className={classes.formInfoBold}>Weight unit : </span>
                          {formikProps.values.weightUnit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.formInfo}>
                        <span className={classes.formInfoBold}>Mode : </span>
                        {formikProps.values.mode}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            }

            return (
              <Form style={{ marginTop: "40px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className={classes.label}>
                      Origin
                      <CustomTextField
                        fullWidth
                        variant="outlined"
                        name="origin"
                        placeholder="Location"
                        value={formikProps.values.origin}
                        onChange={formikProps.handleChange}
                        error={
                          getIn(formikProps.touched, "origin") &&
                          getIn(formikProps.errors, "origin")
                        }
                        helperText={
                          getIn(formikProps.touched, "origin") &&
                          getIn(formikProps.errors, "origin")
                        }
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <label className={classes.label}>
                      Destination
                      <CustomTextField
                        fullWidth
                        variant="outlined"
                        name="destination"
                        placeholder="Location"
                        value={formikProps.values.destination}
                        onChange={formikProps.handleChange}
                        error={
                          getIn(formikProps.touched, "destination") &&
                          getIn(formikProps.errors, "destination")
                        }
                        helperText={
                          getIn(formikProps.touched, "destination") &&
                          getIn(formikProps.errors, "destination")
                        }
                      />
                    </label>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <label className={classes.label}>
                      Weight
                      <CustomTextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="weight"
                        placeholder="Weight"
                        value={formikProps.values.weight}
                        onChange={formikProps.handleChange}
                        error={
                          getIn(formikProps.touched, "weight") &&
                          getIn(formikProps.errors, "weight")
                        }
                        helperText={
                          getIn(formikProps.touched, "weight") &&
                          getIn(formikProps.errors, "weight")
                        }
                      />
                    </label>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <label className={classes.label}>
                      Weight Unit
                      <SelectFieldStyled
                        noFixedWidth
                        noMarginBottom
                        name="weightUnit"
                        value={formikProps.values.weightUnit}
                        onChange={formikProps.handleChange}
                        options={weightUnitOptions}
                        label={""}
                        placeholder="Select from list"
                        defaultSelectLabel="Select from list"
                        error={
                          getIn(formikProps.touched, "weightUnit") &&
                          getIn(formikProps.errors, "weightUnit")
                        }
                        errorMessage={
                          getIn(formikProps.touched, "weightUnit") &&
                          getIn(formikProps.errors, "weightUnit")
                        }
                      />
                    </label>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label className={classes.label}>
                      Mode
                      <SelectFieldStyled
                        noFixedWidth
                        noMarginBottom
                        name="mode"
                        value={formikProps.values.mode}
                        onChange={formikProps.handleChange}
                        options={transportModeOptions}
                        label={""}
                        placeholder="Select from list"
                        defaultSelectLabel="Select from list"
                        error={
                          getIn(formikProps.touched, "mode") &&
                          getIn(formikProps.errors, "mode")
                        }
                        errorMessage={
                          getIn(formikProps.touched, "mode") &&
                          getIn(formikProps.errors, "mode")
                        }
                      />
                    </label>
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  disableElevation
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={clsx(
                    classes.btn,
                    formikProps.dirty
                      ? classes.calculateButton
                      : classes.disabledCalculateButton,
                  )}
                >
                  Calculate Emission
                </Button>
                <Button
                  fullWidth
                  disableElevation
                  type="reset"
                  variant="outlined"
                  color="primary"
                  style={{ display: formikProps.isValid && formikProps.dirty ? "flex" : "none" }}
                  className={`${classes.btn} ${classes.clearButton}`}
                >
                  Clear
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "0 52px",
      '@media(max-width: 768px)': {
        margin: "0 15px",
      }
    },
    logoContainer: {
      boxShadow: "0px 0px 4px 0px #00000040",
      cursor: "pointer",
    },
    logo: {
      margin: "16px 0 16px 52px",
      '@media(max-width: 768px)': {
        margin: "16px 0 16px 15px",
      }
    },
    titleDescriptionContainer: {
      marginTop: "40px"
    },
    title: {
      fontFamily: "Lato",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "28.8px",
      textAlign: "left",
      color: "#34373A",
    },
    description: {
      fontFamily: "Lato",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left",
      color: "#34373A",
      marginTop: "15px",
    },
    label: {
      fontFamily: "Lato",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "22px",
      textAlign: "left",
      color: "#34373A"
    },
    btn: {
      height: "40px",
      padding: "16px 12px 16px 12px",
      borderRadius: "8px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "19.2px",
      textAlign: "center",
      color: "#34373A",
      textTransform: "capitalize",
    },
    calculateButton: {
      backgroundColor: "#43D270",
      marginTop: "30px",
      "&:hover": {
        backgroundColor: "#43D270"
      }
    },
    disabledCalculateButton: {
      backgroundColor: "#D9D9D9",
      marginTop: "30px",
      pointerEvents: "none",
      "&:hover": {
        backgroundColor: "#D9D9D9"
      }
    },
    clearButton: {
      border: "1px solid #D9D9D9",
      marginTop: "15px",
      "&:hover": {
        backgroundColor: "#FFF",
        borderColor: "#D9D9D9"
      }
    },
    card: {
      width: "309px",
      borderRadius: "8px",
      border: "1px solid #34373A33",
      padding: "15px 20px",
      marginTop: "40px",
    },
    cardTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "4px",
    },
    cardTitle: {
      fontFamily: "Lato",
      fontSize: "17px",
      fontWeight: 700,
      lineHeight: "23.8px",
      textAlign: "left",
      color: "#34373A"
    },
    editBtn: {
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "19.2px",
      textAlign: "right",
      color: "#43D270",
      textTransform: "capitalize",
    },
    formInfoContainer: {
      display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    formInfo: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      color: "#7D7D7F",
      marginBottom: "5px",
      textTransform: "capitalize",
    },
    formInfoBold: {
      color: "#34373A",
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px"
    }
  })
);
