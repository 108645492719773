import React from "react";

import {
  // Customizable Area Start
  Box,
  Input,
  Button,
  Typography,
  Modal,
  Select,
  Snackbar,
  MenuItem,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import { editImg, EyeOff, ShowEye, checkImg, crossImage, blackCheckImg } from "../assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: "389px",
  '@media(max-width: 1200px)': {
    gap: "20px",
  },
});

const StyledBox2 = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "24px",
  '@media(max-width: 700px)': {
    flexDirection: "column"
  },
});

const StyledBox3 = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "95%",
  '@media(max-width: 700px)': {
    flexDirection: "column"
  },
});

const StyledInput = styled(Field)({
  width: "50%",
  height: "48px",
  borderRadius: "8px",
  border: "1px solid #D9D9D9",
  background: "#FFF",
  padding: "12px",
  paddingRight: "40px",
  '@media(max-width: 700px)': {
    width: "100%",
  },
});
const StyledImage = styled('img')({
  width: "20px",
  height: "20px",
  position: "relative",
  top: "-41px",
  left: "47%",
  cursor: 'pointer',
  '@media(max-width: 700px)': {
    left: "85%",
  },
});

const DivWrapper = styled(Box)({
  width: "28%",
  padding: "16px",
  borderRadius: "12px",
  border: "0.6px solid rgba(0, 0, 0, 0.18)",
  background: "#FEFEFF",
  boxShadow: "4px 8px 20px 0px rgba(0, 0, 0, 0.08)",
  position: "absolute",
  left: "40%",
  top: "100px",
  maxHeight: "685px",
  '@media(max-width: 700px)': {
    width: "90%",
    left: "5%"
  },
});

const StyledError = styled('p')({
  color: "#F5222D",
  fontFamily: "Lato",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px"
})
// Customizable Area End

import DashboardController, {
  Props,
  configJSON,
} from "../DashboardController";

export default class ProfileInfo extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let data = configJSON
    data = []
    console.log(data)
    this.getProfileData()
  }

  renderIcon = (props: any) => (
    <IconButton
      {...props}
      onClick={this.handleProfileOpenIcon}
      style={{
        height: "16px", width: "16px", display: "flex", justifyContent: "center",
        alignItems: "center", marginRight: '12px'
      }}
    >
      {!this.state.openSelectbox ? <div>
        <ExpandMoreIcon style={{ color: '#34373A', width: "16px" }} />
      </div> :
        <div style={{ transform: "rotate(180deg)" }}>
          <ExpandMoreIcon style={{ color: '#34373A', width: "16px" }} />
        </div>}
    </IconButton>)
  // Customizable Area End

  render() {
    // Customizable Area Start
    let { firstName, lastName, contactNo } = this.state.personalData;
    let { selectedCountry, error, errorMessage, paswordStatus, status, btnColor, showPass1, showPass2, showPass3, getProfileResponse } = this.state
    let { first_name, last_name, full_name, email, phone_no } = this.state.profileData
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={webStyle.mainDiv}
        >
          <StyledBox>
            <StyledBox2>
              <div>
                <Button component="label" style={{
                  width: "118.024px",
                  height: "118px",
                  borderRadius: "118.024px",
                  border: "2px solid #DBDADF",
                  textAlign: "center",
                  boxShadow: "none",
                  backgroundColor: "#F5F9F1",

                }}
                >
                  {this.state.profileImageURL ?
                    <img src={this.state.profileImageURL} alt=""
                      style={{ width: "118.024px", height: "118px", borderRadius: "118.024px" }} />
                    : <Typography style={{ fontSize: "50px", fontWeight: "bold" }}>{((first_name || "").charAt(0)).toUpperCase()}</Typography>}

                  <VisuallyHiddenInput data-test-id="file-input" type="file" onChange={this.handleProfileImage} />
                </Button>
              </div>
              <div>
                <Typography style={webStyle.text} >{full_name}</Typography>
              </div >
            </StyledBox2 >
            <div style={webStyle.editDiv} data-test-id="infoModal12" onClick={() => this.setState({ editInfoModal: true })}>
              <div >
                <Typography style={webStyle.editText}>Edit </Typography>
              </div>
              <div>
                <img src={editImg} alt="" style={{ width: "20px", height: "20px" }} />
              </div >
            </div >

          </StyledBox>
        </Box>
        <div
          style={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRadius: "8px",
            width: "80%",
            gap: "10px",
            marginTop: "24px",
            border: "0.6px solid rgba(52, 55, 58, 0.20)",
          }}
        >
          <Typography style={webStyle.compInfo}>Personal Information</Typography>
          <StyledBox3>
            <div >
              <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <div>
                  <Typography style={webStyle.multiUsedTypo}>First Name</Typography>
                  <Typography style={webStyle.multiUsedTypo2}>{first_name}</Typography>
                </div>
                <div>
                  <Typography style={webStyle.multiUsedTypo}>Email Address</Typography>
                  <Typography style={webStyle.multiUsedTypo2}>{email}</Typography>
                </div>
              </div>

            </div>
            <div >
              <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <div>
                  <Typography style={webStyle.multiUsedTypo}>Last Name</Typography>
                  <Typography style={webStyle.multiUsedTypo2}>{last_name}</Typography>
                </div>
                <div>
                  <Typography style={webStyle.multiUsedTypo}>Main Phone</Typography>
                  <Typography style={webStyle.multiUsedTypo2}>{phone_no ? `+${this.state.countryCode} ${phone_no}` : ""}</Typography>
                </div>
              </div>

            </div>
            <div >
              <Typography style={webStyle.multiUsedTypo}>Display Name</Typography>
              <Typography style={webStyle.multiUsedTypo2}>{full_name}</Typography>
            </div>

          </StyledBox3>
        </div>
        <Formik
          initialValues={this.state.passwordData}
          validate={this.handleValidation}
          onSubmit={this.changePasswordApi}
        >
          <Form>
            <div style={{
              width: "80%",
              padding: "24px",
              borderRadius: "8px",
              marginTop: "24px",
              border: "0.6px solid rgba(52, 55, 58, 0.20)"
            }}>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                <Typography>Security</Typography>

                <div style={{
                  display: "flex",
                  padding: "8px 12px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  borderRadius: "4px",
                  border: "1px solid #DBDADF",
                  width: "73px",
                  height: "38px",
                  cursor: "pointer",
                  backgroundColor: `${!error ? "#43D270" : "white"}`
                }} >
                  <div >
                    <Button style={{
                      color: `${!error ? "#34373A" : "#7D7D7F"}`,
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "160%",
                      border: "none",
                      background: "none",
                      textTransform: "capitalize"
                    }} endIcon={<img src={!error ? blackCheckImg : checkImg} alt="" style={{ width: "20px", height: "20px" }} />} type="submit" >Save </Button>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>

                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px" }}>
                  <Typography style={webStyle.formLabel}> Current Password</Typography>
                  <StyledInput
                    type={showPass1 ? 'text' : 'password'}
                    placeholder="Enter current password"
                    name="currentPass"
                    disableUnderline style={{ ...webStyle.placeholderStyle }} />
                  <StyledImage src={!showPass1 ? EyeOff : ShowEye} alt=""
                    onClick={() => this.handleShowHide("1")}
                  />
                  <ErrorMessage name="currentPass" component="div" render={(msg) => <div style={webStyle.errMsg}>{msg}</div>} />
                </div>

                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px" }}>
                  <Typography style={webStyle.formLabel}>New Password</Typography>
                  <StyledInput
                    type={showPass2 ? 'text' : 'password'}
                    placeholder="Enter new password"
                    name="newPass"
                    disableUnderline style={{ ...webStyle.placeholderStyle }} />
                  <StyledImage src={!showPass2 ? EyeOff : ShowEye} alt=""
                    onClick={() => this.handleShowHide("2")}
                  />
                  <ErrorMessage name="newPass" component="div" render={(msg) => <div style={webStyle.errMsg}>{msg}</div>}
                  />
                </div>

                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px" }}>
                  <Typography style={webStyle.formLabel}>Confirm New Password</Typography>
                  <StyledInput
                    type={showPass3 ? 'text' : 'password'}
                    placeholder="Please confirm new password"
                    name="confirmNewPass"

                    style={{ ...webStyle.placeholderStyle }}
                    disableUnderline />
                  <StyledImage src={!showPass3 ? EyeOff : ShowEye} alt=""
                    onClick={() => this.handleShowHide("3")}
                  />
                  <ErrorMessage name="confirmNewPass" component="div" render={(msg) => <div style={webStyle.errMsg}>{msg}</div>} />
                </div>


              </div>


            </div>
          </Form>
        </Formik>
        <Modal
          open={this.state.editInfoModal}
        >
          <DivWrapper>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "22px" }}>
              <Typography
                style={{
                  color: "#34373A",
                  fontFamily: "Lato",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "140%",
                  letterSpacing: "0.2px"
                }}> Personal Information</Typography>
              <img src={crossImage} data-testid="cross-icon" onClick={() => this.handleCloseProfileModal()} style={{ height: "24px", width: "24px", cursor: "pointer" }} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                <Typography style={webStyle.formLabel}> First Name</Typography>
                <Input type="text" placeholder="Enter your firstname"
                  value={firstName}
                  name="firstName"
                  onChange={this.handlePersonalData}
                  disableUnderline style={{ ...webStyle.formInput, ...webStyle.placeholderStyle }} />
                {this.state.formError && !firstName && <StyledError>Can't leave this field blank</StyledError>}
              </div>

              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                <Typography style={webStyle.formLabel}>Last Name</Typography>
                <Input type="text" placeholder="Enter your lastname"
                  value={lastName}
                  name="lastName"
                  onChange={this.handlePersonalData}
                  disableUnderline style={{ ...webStyle.formInput, ...webStyle.placeholderStyle }} />
                {this.state.formError && !lastName && <StyledError>Can't leave this field blank</StyledError>}
              </div>

              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px", marginBottom: "31px" }}>
                <Typography style={webStyle.formLabel}> Main Phone</Typography>
                <PhoneInput
                  country={selectedCountry}
                  value={contactNo}
                  placeholder=""
                  onChange={this.handleOnChange}
                  inputStyle={{ ...webStyle.formInput, ...webStyle.placeholderStyle, paddingLeft: "50px" }}
                />
              </div>


              <Button
                data-testid="save-button"
                style={{ ...webStyle.btn, background: `${!btnColor ? "#B1B3C3" : "#43D270"}` }}
                onClick={this.updateProfileData}
              > Save</Button>

            </div>

          </DivWrapper>
        </Modal>

        <Snackbar
          data-test-id={'profile-snackbar'}
          open={status}
          onClose={() => this.setState({ status: false, paswordStatus: false })}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert severity={"success"}>
            {!paswordStatus ? "Profile Info Saved Successfully." : "Password Updated Successfully."}
          </Alert>
        </Snackbar>

        <Snackbar
          data-test-id={'profile-snackbar2'}
          open={Boolean(errorMessage)}
          onClose={() => this.setState({ status: false, paswordStatus: false, errorMessage: "" })}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert severity={"error"} >
            {errorMessage}
          </Alert>
        </Snackbar>

      </ThemeProvider>
    );
    // Customizable Area End
  }

}

// Customizable Area Start
const webStyle = {
  btn: {
    display: "flex",
    height: "40px",
    padding: "7px 16px 7px 12px",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "8px",
  },
  text: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "150%",
    letterSpacing: "0.2px",
    paddingBottom: "12px"
  },
  mainDiv: {
    display: "flex",
    padding: "24px",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px",
    border: "0.6px solid rgba(52, 55, 58, 0.20)",
    width: "80%"
  },
  typoText: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%"
  },
  editText: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%"
  },
  errMsg: {
    color: "red",
    marginTop: "-25px",
    fontFamily: 'Lato'
  },
  compInfo: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    paddingBottom: "16px"
  },
  multiUsedTypo: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%"
  },
  multiUsedTypo2: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%"
  },
  editDiv: {
    display: "flex",
    padding: "8px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "4px",
    border: "1px solid #DBDADF",
    width: "73px",
    height: "38px",
    cursor: "pointer"
  },
  generalInfoDiv: {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px",
    border: "0.6px solid rgba(52, 55, 58, 0.20)",
    marginTop: "24px",
    width: "80%"
  },
  formInput: {
    width: "100%",
    height: "48px",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    padding: "12px"
  },
  formLabel: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px"
  },
  passInput: {
    width: "50%",
    height: "48px",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    padding: "12px"
  },
  placeholderStyle: {
    fontFamily: "Lato",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  img: {
    width: "20px",
    height: "20px",
    position: "relative",
    top: "-41px",
    left: "480px"
  }
};
// Customizable Area End
