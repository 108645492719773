import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface Header {
  token: string;
  "Content-Type"?: string;
}

interface EmissionData {
  id: number;
  name: string;
  value: string;
  source: string;
  last_updated: string;
  restatement?: string;
}

// Common method to call apis
export const apiCall = async (data: any) => {
  const { method, endPoint, payload, contentType } = data;
  const token = localStorage.getItem("token") ?? "";
  const header: Header = { token };

  if (contentType) {
    header["Content-Type"] = contentType;
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  payload &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  value?: number;
  index?: number;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  emissionDetailsId: number;
  openAssurance1: boolean;
  noHeadingData: EmissionData[];
  assurance1: EmissionData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfnzdpuassuranceandverificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAssuranceAndVerificationApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      emissionDetailsId: 0,
      openAssurance1: true,
      noHeadingData: [],
      assurance1: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Receive emission details id from state after navigation
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );

      this.setState({ emissionDetailsId: data.emissionDetailsId });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.getAssuranceAndVerificationApiCallId) {
        this.handleAssuranceAndVerificationResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidUpdate(
    prevProps: Readonly<Props>, 
    prevState: Readonly<S>, 
    snapshot?: SS | undefined
  ): void {
    if (prevState.emissionDetailsId !== this.state.emissionDetailsId && this.state.emissionDetailsId > 0) {
      // Call api to get assurance and verification when we receive a new valid emission details id
      this.getAssuranceAndVerification(this.state.emissionDetailsId);
    }
  }

  // Get details for scope 1 emissions
  getAssuranceAndVerification = async (emissionDetailsId: number) => {
    this.getAssuranceAndVerificationApiCallId = await apiCall({
      contentType: configJSON.apiContentTypeApplicationJson,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.assuranceAndVerificationAPiEndPoint,
      payload: JSON.stringify({ metadata_id: emissionDetailsId }),
    }); 
  }

  // Handle scope 2 emission details response
  handleAssuranceAndVerificationResponse = (response: any) => {
    if (response.assurance_verification_list) {
      this.setState({
        noHeadingData: response.assurance_verification_list["NOHEADING"],
        assurance1: response.assurance_verification_list["Assurance/Verification 1"],
      });
    }
  };

  // Toggle assurance 1 collapse
  handleToggleAssurance1 = () => {
    this.setState((prevState: S) => {
      return {
        ...prevState,
        openAssurance1: !prevState.openAssurance1,
      }
    })
  }
  // Customizable Area End
}
