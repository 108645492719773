import React from "react";
// Customizable Area Start
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography, Box } from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { trash } from "./assets";
import StripeCheckout from 'react-stripe-checkout';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    }
});

const tableLabels = {
    plan: "Plan",
    price: "Price",
    totalprice: "Total Price",
    remove: ''
};

const BillTableContainer = styled(TableContainer)({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: '36px',
    overflowX: "visible"
});

const BillTable = styled(Table)({
    width: "100%",
    borderCollapse: "separate",
    '@media(max-width: 425px)': {
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap",
        minHeight: 600,
    }
});

const BillTableBody = styled(TableBody)({
    color: "inherit",
});

const CustomSpacing = styled("div")({
    height: 16,
    display: "table-header-group",
});

const BillTableCellHead = styled(TableCell)({
    minHeight: "48px",
    padding: "8px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    // maxWidth: '400px'
});

const BillTableCell = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    // maxWidth: '400px'
});

const BillTableCellTotal = styled(TableCell)({
    border: 'none',
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    width: '270px'
});

const BillTableHead = styled(TableHead)({
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
});

const BillTableRowHead = styled(TableRow)({
    borderRadius: "20px !important",
    overflow: "hidden",
    "& :first-child": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    "& :last-child": {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
});

const BillTableRow = styled(TableRow)({});

const StripeButton = styled('button')({
    borderRadius: "8px",
    backgroundColor: "#43d270",
    width: '355px',
    height: "40px",
    display: "flex",
    padding: "8px 8px 8px 12px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#34373A",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: " 19.2px",
    border: "none",
    cursor: "pointer",
    '@media(max-width: 625px)': {
        width: 165
    }
});

const Container = styled(Box)({
    padding: '0px 67px 49px 45px',
    '@media(max-width: 768px)': {
        padding: '0px 10px 49px 10px',
    }
});
// Customizable Area End

import CustomisableusersubscriptionsController, {
    Props
} from "./CustomisableusersubscriptionsController";

export default class Billing extends CustomisableusersubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getPlans()
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { PlanInfo } = this.state

        const checkout = [
            {
                "id": 1,
                "level": "Sub Total",
                "amount": "",
                "currency_type": "$",
                total: this.getamount(),
                button: false,
                delete: false,
            },
            {
                "id": 2,
                "level": "Total",
                "amount": "",
                "currency_type": "$",
                total: this.getamount(),
                button: false,
                delete: false,
            },
            {
                "id": 3,
                "level": "",
                "amount": "",
                "currency_type": "",
                total: "",
                button: true,
                delete: false,
            }]
        return (
            <ThemeProvider theme={theme}>
                <Container>
                    {
                        PlanInfo ?
                            <>
                                <Typography style={webStyle.cart}>Cart <span style={webStyle.item}>(1 item)</span></Typography>
                                <BillTableContainer>
                                    <BillTable aria-label="users table">
                                        <BillTableHead>
                                            <BillTableRowHead>
                                                <BillTableCellHead>
                                                    {tableLabels.plan}
                                                </BillTableCellHead>
                                                <BillTableCellHead>{tableLabels.price}</BillTableCellHead>
                                                <BillTableCellHead style={{ textAlign: 'right' }}>{tableLabels.totalprice}</BillTableCellHead>
                                                <BillTableCellHead>{tableLabels.remove}</BillTableCellHead>
                                            </BillTableRowHead>
                                        </BillTableHead>
                                        <CustomSpacing />
                                        <BillTableBody>
                                            <BillTableRow>
                                                <BillTableCell style={{ fontWeight: 700 }}>
                                                    {PlanInfo?.level ?? ""}
                                                </BillTableCell>
                                                <BillTableCell>
                                                    {PlanInfo.currency_type}{this.getamount()}
                                                </BillTableCell>
                                                <BillTableCell style={{ textAlign: 'right' }}>
                                                    {PlanInfo.currency_type}{this.getamount()}
                                                </BillTableCell>
                                                <BillTableCell style={{ textAlign: 'center' }}>
                                                    <IconButton data-test-id="remove" onClick={this.handleDelete}>
                                                        <img src={trash} alt="delete" />
                                                    </IconButton>
                                                </BillTableCell>
                                            </BillTableRow>
                                            <BillTableRow>
                                                <BillTableCell>
                                                    { }
                                                </BillTableCell>
                                                <BillTableCell>
                                                    { }
                                                </BillTableCell>
                                                <BillTableCell style={{ textAlign: 'right', fontWeight: 700 }}>
                                                    {PlanInfo.currency_type}{this.getamount()}
                                                </BillTableCell>
                                                <BillTableCell>
                                                </BillTableCell>
                                            </BillTableRow>
                                            <Typography style={webStyle.title}>Order Summary</Typography>
                                            {checkout && checkout.map((plan) => {
                                                return (
                                                    <BillTableRow style={{ position: "relative", top: '50px' }} >
                                                        <BillTableCellTotal>
                                                            {plan.level}
                                                        </BillTableCellTotal>
                                                        <BillTableCellTotal>
                                                            {plan.amount && `${plan.currency_type}${plan.amount}`}
                                                        </BillTableCellTotal>
                                                        <BillTableCellTotal style={{
                                                            textAlign: 'right',
                                                            color: plan.id === 2 ? "#329E54" : '#34373A',
                                                            fontSize: plan.id === 2 ? "16px" : '14px'
                                                        }}>
                                                            {plan.button ?
                                                                <StripeCheckout
                                                                    token={this.handleToken}
                                                                    stripeKey="pk_live_51OD5DEHmPYcuiKRJH29F4fCCqqo0YaOhpdz4Vl25CH3jFj4R9A83MZXw0j9eBvXwIq7LMl4txSknqfBHeJNBmLYt00JA5gadrf"
                                                                    amount={this.getamountStripe() ?? 0 * 100}
                                                                    currency="USD"
                                                                    name="CarbonZeroed"
                                                                >
                                                                    <StripeButton>Check Out with Stripe</StripeButton>
                                                                </StripeCheckout>
                                                                : `${plan.currency_type}${plan.total}`}
                                                        </BillTableCellTotal>
                                                        <BillTableCellTotal>
                                                        </BillTableCellTotal>
                                                    </BillTableRow>
                                                );
                                            })}
                                        </BillTableBody>
                                    </BillTable>
                                </BillTableContainer>
                            </>
                            : <Typography style={webStyle.noitem}>No plan found</Typography>
                    }

                </Container>
            </ThemeProvider >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    cart: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "27.5px"
    },
    item: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "27.5px"
    },
    container: {
        padding: '0px 67px 49px 45px'
    },
    title: {
        marginTop: '35px',
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "27.5px"
    },
    checkoutContainer: {
        display: 'flex',
        gap: '27px',
        flexDirection: 'column',
        marginTop: '50px'
    } as any,
    innerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperStyle: {
        width: '460px',
        maxWidth: "100%",
        minHeight: '250px',
        padding: '20px 20px 32px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
    },
    noitem: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "27.5px",
        textAlign: "center" as "center"
    },
}
// Customizable Area End