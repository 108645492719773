import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface Header {
  token: string;
  "Content-Type"?: string;
}

interface EmissionData {
  id: number;
  name: string;
  value: string;
  source: string;
  last_updated: string;
  restatement?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  value?: number;
  index?: number;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabvalue: number;
  emissionDetailsId: number;
  totalScope1Emissions: EmissionData[];
  rationaleIfNotDisclosed: EmissionData[];
  carbonDioxide: EmissionData[];
  methane: EmissionData[];
  nitrousOxide: EmissionData[];
  hydrofluorocarbons: EmissionData[];
  perfluorocarbons: EmissionData[];
  sulphurHexafluoride: EmissionData[];
  nitrogenTrifluoride: EmissionData[];
  otherGreenhouseGas1: EmissionData[];
  otherGreenhouseGas2: EmissionData[];
  otherGreenhouseGas3: EmissionData[];
  otherGreenhouseGas4: EmissionData[];
  otherGreenhouseGas5: EmissionData[];
  otherGreenhouseGas6: EmissionData[];
  methodology: EmissionData[];
  exclusion1: EmissionData[];
  changeInScope1EmissionsCalculation: EmissionData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfnzdpuscope1emissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScope1EmisstionDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabvalue: 0,
      emissionDetailsId: 0,
      totalScope1Emissions: [],
      rationaleIfNotDisclosed: [],
      carbonDioxide: [],
      methane: [],
      nitrousOxide: [],
      hydrofluorocarbons: [],
      perfluorocarbons: [],
      sulphurHexafluoride: [],
      nitrogenTrifluoride: [],
      otherGreenhouseGas1: [],
      otherGreenhouseGas2: [],
      otherGreenhouseGas3: [],
      otherGreenhouseGas4: [],
      otherGreenhouseGas5: [],
      otherGreenhouseGas6: [],
      methodology: [],
      exclusion1: [],
      changeInScope1EmissionsCalculation: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Receive emission details id from state after navigation
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );

      this.setState({ emissionDetailsId: data.emissionDetailsId });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.getScope1EmisstionDetailsApiCallId) {
        this.handleScope1EmissionDetailsResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  componentDidUpdate(
    prevProps: Readonly<Props>, 
    prevState: Readonly<S>, 
    snapshot?: SS | undefined
  ): void {
    if (prevState.emissionDetailsId !== this.state.emissionDetailsId && this.state.emissionDetailsId > 0) {
      // Call api to get details of scope 1 emission details when we receive a new valid emission details id
      this.getScope1EmissionDetails(this.state.emissionDetailsId);
    }
  }

  // Common method to call apis
  apiCall = async (data: any) => {
    const { method, endPoint, payload, contentType } = data;
    const token = localStorage.getItem("token") ?? "";
    const header: Header = { token };
  
    if (contentType) {
      header["Content-Type"] = contentType;
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
  
    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Get details for scope 1 emissions
  getScope1EmissionDetails = async (emissionDetailsId: number) => {
    this.getScope1EmisstionDetailsApiCallId = await this.apiCall({
      contentType: configJSON.apiContentTypeApplicationJson,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.scope1EmisstionDetailsAPiEndPoint,
      payload: JSON.stringify({ metadata_id: emissionDetailsId }),
    }); 
  }

  // Handle scope 1 emission details response
  handleScope1EmissionDetailsResponse = (response: any) => {
    if (response.scope1_list) {
      this.setState({
        totalScope1Emissions: response.scope1_list["Total Scope 1 Emissions"],
        rationaleIfNotDisclosed: response.scope1_list["Rationale if Not Disclosed"],
        carbonDioxide: response.scope1_list["Carbon Dioxide (CO₂)"],
        methane: response.scope1_list["Methane (CH₄)"],
        nitrousOxide: response.scope1_list["Nitrous Oxide (N₂O)"],
        hydrofluorocarbons: response.scope1_list["Hydrofluorocarbons (HFCs)"],
        perfluorocarbons: response.scope1_list["Perfluorocarbons (PFCs)"],
        sulphurHexafluoride: response.scope1_list["Sulphur Hexafluoride (SF₆)"],
        nitrogenTrifluoride: response.scope1_list["Nitrogen Trifluoride (NF₃)"],
        otherGreenhouseGas1: response.scope1_list["Other greenhouse gas 1"],
        otherGreenhouseGas2: response.scope1_list["Other greenhouse gas 2"],
        otherGreenhouseGas3: response.scope1_list["Other greenhouse gas 3"],
        otherGreenhouseGas4: response.scope1_list["Other greenhouse gas 4"],
        otherGreenhouseGas5: response.scope1_list["Other greenhouse gas 5"],
        otherGreenhouseGas6: response.scope1_list["Other greenhouse gas 6"],
        methodology: response.scope1_list["Methodology"],
        exclusion1: response.scope1_list["Exclusion 1"],
        changeInScope1EmissionsCalculation: response.scope1_list["Change in Scope 1 emissions calculation or reporting boundary"],
      });
    }
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log("newValue", newValue)
    this.setState({ tabvalue: newValue });
  };


  // Customizable Area End
}
