import React, { useEffect, useState } from "react";
import { CSSProperties } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  TextField,
  Typography,
  styled,
  Select,
  MenuItem,
  Snackbar,
  FormControl
} from "@material-ui/core";

import Alert from '@material-ui/lab/Alert';

import InputAdornment from "@material-ui/core/InputAdornment";
import clsx from "clsx";
import { Theme } from "react-native-elements";
import { handleDrag, handleDrop } from "../../DynamicContent/src/Jobs/JobsForm.web";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldStyle: {
    '& *': {
      borderRadius: "8px !important",
      borderColor: "#D9D9D9 !important",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px",
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px",
    },
    width: fieldMaxWidth,
    height: "48px",
    "@media(max-width:550px)": {
      width: "350px",
    },
    "@media(max-width:450px)  ": {
      width: "320px",
    },
    "@media(max-width:400px)": {
      width: "304px",
    },
  },
  textStyle: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
    borderRadius: "8px",
    color: "#34373A",
    boxSizing: "border-box",
    padding: "0 15px",
    height: "48px",
  },
  fileUploadField: {
    textDecoration: "none",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#595959",
    lineHeight: "24px",
    fontSize: "15px",
    '& *': {
      cursor: "pointer",
    },
  },
  dropZoneField: {
    fontWeight: 700,
    fontFamily: "Lato",
    textDecoration: "none",
    color: "#595959",
    fontSize: "15px",
    height: "100%",
    lineHeight: "24px",
    background: "#efefef",
    '& *': {
      cursor: "pointer",
    },
  },

  formBtn: {
    color: "#101010",
    fontWeight: 500,
    fontFamily: "Lato",
    fontSize: "15px",
    width: "184px",
    height: "56px",
    borderRadius: "8px",
    textAlign: "center" as CSSProperties["textAlign"],
    padding: "16px 12px",
    backgroundColor: "#43D270",
    marginBottom: "40px",
    "&:hover": {
      backgroundColor: "#3ca15e",
    },
    "@media(max-width:600px)": {
      fontSize: "12px",
      width: "100px",
      padding: "12px 20px",
    },
    "&.MuiButton-contained": {
      boxShadow: "unset",
    },
  },
  disabledformBtn: {
    color: "#000000",
    fontFamily: "Lato",
    fontSize: "15px",
    width: "184px",
    textAlign: "center" as CSSProperties["textAlign"],
    height: "56px",
    borderRadius: "8px",
    fontWeight: 500,
    marginBottom: "40px",
    padding: "16px 12px",
    backgroundColor: "#B1B3C3",
    "@media(max-width:600px)": {
      width: "100px",
      padding: "12px 20px",
      fontSize: "12px",
    },
    "&.MuiButton-contained": {
      boxShadow: "unset",
    },
  },
  fileInput: {
    display: "none",
  },
  fileInput2: {
    cursor: "pointer",
  },
  errorMessage: {
    color: "#ff0000",
    lineHeight: 1,
    marginTop: "10px",
  },
  muiSelectSelect: {
    padding: "8px 15px"
  },
  muiSelectSelectActive: {
    padding: "8px 15px",
    color: "#B1B3C3",
  },
  selectIcon: {
    cursor: 'pointer',
  },
  selectMenu:{
'@media (max-width:600px)':{
  width: "319px !important",
  marginTop:'0px !important',
  marginLeft:'0px !important'
 }
}
}));

const fieldMaxWidth = "404px";

const FormBox = styled('div')({
  width: '100%',
  maxWidth: fieldMaxWidth,
  paddingTop: "30px",
});

const FieldBottomDesc = styled(Typography)({
  lineHeight: "22px",
  marginTop: "13px",
  marginBottom: "40px",
  fontFamily: "Lato",
  fontWeight: 400,
  fontSize: "14px",
  color: "#B1B3C3",
});

const TextFieldStyled = styled(TextField)({
  width: '100%',
  borderRadius: "8px",
  maxWidth: fieldMaxWidth,
  outline: "1 important",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: 1,
});

const FieldLabel = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#101010",
  marginBottom: "8px",
})

const ErrorAlert = styled('div')({
  color: "#ff0000",
  lineHeight: 1,
  marginTop: "10px",
})

const FieldGroup = styled(Box)({
  marginBottom: "40px",
})

const CustomSelectField = styled(Select)({
  height: "48px",
  borderRadius: "8px",
  padding: "0px !important;",
  "& *": {
    background: "#fff !important;",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
  },
  '&:focus': {
    borderColor: '#cccccc',
  },
})

const Dropdown = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  maxWidth: fieldMaxWidth,
  border: '1px solid rgba(0, 0, 0, 0.2)',
})

const PreviewWr = styled(Box)({
  position: "relative",
  display: "inline-block",

})

const CloseIconStyled = styled(CloseIcon)({
  top: "-15px",
  right: "-10px",
  position: "absolute",
  fontSize: "17px",
  background: "#eee",
  borderRadius: "100%",
  cursor: "pointer",
  padding: "1px",
})

const PreviewImage = styled('img')({
  borderRadius: '4px',
  width: "auto",
  maxWidth: "150px",
  maxHeight: "100px",
  marginBottom: "5px",
  padding: "5px",
  background: "#efefef"
})

interface FormData {
  company_name: string,
  company_website: string;
  company_registered_address: string;
  industry_sector_id: string;
  number_of_employees: string;
  company_logo: any;
  account_id: string | number;
}

interface OrganizationInfoFormPropTypes {
  onSubmit?: any;
  position: string;
  resData?: any;
}

export const CustomDropdownIcon = (props: any) => {
  return (
    <svg
      style={{ marginRight: "12px" }}
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.5L10 12.5L5 7.5"
        stroke="#7D7D7F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const checkFieldValidation = (fieldsData: any, formData: any) => {
  if (!formData)
    return false;

  return fieldsData.every((item: any) => {
    if (item.isMandatory) {
      const fieldValue = formData[item.fieldName as keyof FormData];
      if ((typeof (fieldValue) === "string" && fieldValue.trim() === "")  || (!fieldValue)) {
        return false;
      }
    }
    return true;
  });
}

export const getFile = (isFilled: boolean, name: string) => {
  if (isFilled) {
    return name
  } else {
    return ""
  }
}

export const getFilePlaceholder = (fieldInputValue: string) => {
  if (!fieldInputValue) {
    return "Drop here from desktop or simply click and attach"
  } else {
    return ""
  }
}

const initialFormData = {
  company_name: "",
  company_website: "",
  company_registered_address: "",
  industry_sector_id: "",
  number_of_employees: "",
  company_logo: "",
  account_id: "",
}

const OrganizationInfoForm = ({ onSubmit, data, status, removelogoimage, logoimg }: any) => {

  const [formData, setFormData] = useState<FormData>(initialFormData)
  const [fileName, setFileName] = useState<string>('')

  const [dragableFileField, setDragableFileField] = useState("")
  const [showErrors, setShowErrors] = useState(false)
  const [showSubmitAlert, setShowSubmitAlert] = useState(false)

  const classes = useStyles();

  const handleOnFileChange = (e: any, field: string) => {
    removeImage()
    const { files } = e.target;
    
    if (files && files.length > 0) {
      const firstFile = files[0];
      setFormData({
        ...formData,
        [field]: firstFile,
      });
      setFileName(firstFile.name)
    } else {
      setFormData({
        ...formData,
        [field]: null,
      });
    }
  };

  const handleOnIconClick = (field: string) => {
    removeImage()
    const fileInput = document.getElementById(`file-input-${field}`);
    fileInput && fileInput.click();
  };


  const fieldsData = [
    {
      fieldLabel: "Company Name*",
      placeholder: "Enter your company name.",
      fieldName: "company_name",
      isMandatory: true,
      type: "text",
      errorMsg: "Company Name is mandatory field.",
      errorMsg2: "",
      isFilled: !!formData.company_website,
    },
    {
      fieldLabel: "Company Website / URL*",
      placeholder: "Enter your company website url.",
      fieldName: "company_website",
      isMandatory: true,
      type: "text",
      errorMsg: "Company Website is mandatory field.",
      errorMsg2: "Enter correct website url. eg. https://www.example.com",
      isFilled: !!formData.company_website,
    },
    {
      fieldLabel: "Company Registered Address*",
      placeholder: "Enter company's registered or HQ address",
      fieldName: "company_registered_address",
      isMandatory: true,
      type: "text",
      errorMsg: "Company Registered Address is mandatory field.",
      errorMsg2: "",
      isFilled: !!formData.company_registered_address,
    },
    {
      fieldLabel: "Industry Type*",
      placeholder: "",
      fieldName: "industry_sector_id",
      isMandatory: true,
      type: "select",
      errorMsg: "Industory Type Address is mandatory field.",
      errorMsg2: "",
      isFilled: !!formData.industry_sector_id,
      options: data?.industry_sector_data,
    },
    {
      fieldLabel: "Number of Employees*",
      placeholder: "",
      fieldName: "number_of_employees",
      isMandatory: true,
      type: "select",
      errorMsg: "Number of Employees is mandatory field.",
      errorMsg2: "",
      isFilled: !!formData.number_of_employees,
      options: data?.number_of_employee_data,
    },
    {
      fieldLabel: "Attach Logo",
      fieldName: "company_logo",
      isMandatory: false,
      placeholder: "Drop here from desktop or simple click and attach",
      type: "file",
      accept: ".png, .jpg, .jpeg, .svg",
      errorMsg: "",
      errorMsg2: "",
      isFilled: !!formData.company_logo,
    },
  ];

  const handleClick = (e: any) => {
    e.preventDefault();
    const areAllFieldsFilled = checkFieldValidation(fieldsData, formData)
    setShowSubmitAlert(true);

    if (areAllFieldsFilled) {
      setShowErrors(false);
      onSubmit && onSubmit(formData)
    } else {
      setShowErrors(true);
    }

  }

  const isFormValid = React.useMemo(() => checkFieldValidation(fieldsData, formData), [fieldsData, formData]);

  useEffect(() => {

    const account_id = JSON.parse(localStorage.getItem("userDetails") as string);
    const company_data = data ? data.company_data : initialFormData;
    if (company_data && account_id) {
      setFormData({
        company_name: company_data.company_name,
        company_website: company_data.company_website,
        company_registered_address: company_data.company_registered_address,
        industry_sector_id: company_data.industry_sector_id,
        number_of_employees: company_data.number_of_employees,
        company_logo: company_data?.company_logo,
        account_id: account_id.meta.id,
      })
    }

  }, [data])

  const removeImage = () => {
    setFormData({
      ...formData,
      company_logo: "",
    });
    setFileName('')
    removelogoimage()
  };

  return (
    <FormBox>
      {fieldsData && fieldsData.map((item: any, index: number) => {
        if (item.type === "text") {
          return (
            <FieldGroup key={`${index}index`}>
              <FieldLabel> {item.fieldLabel}</FieldLabel>
              <TextFieldStyled
                id={`${item.fieldName}-text-input`}
                data-test-id={item.fieldName}
                variant="outlined"
                value={formData[item.fieldName as keyof FormData]}
                placeholder={item.placeholder}
                onChange={(event: any) => {
                  setFormData({
                    ...formData,
                    [item.fieldName]: event.target.value
                  })
                }}
                InputProps={{
                  classes: {
                    input: classes.textStyle,
                  },
                  style: { color: "#34373A", borderRadius: "8px !important;" },
                }}
                className={classes.textFieldStyle}
              // error={showErrors}
              />
              {showErrors && item.isMandatory && (formData[item.fieldName as keyof FormData] === "" || !formData[item.fieldName as keyof FormData]) &&
                <ErrorAlert>{item.errorMsg}</ErrorAlert>
              }
            </FieldGroup>
          );
        }
        if (item.type === "file") {
          const fieldValue = formData[item.fieldName as keyof FormData];
          const fieldInputValue = getFile(item.isFilled, fieldValue.name)
          const placeholder = getFilePlaceholder(fieldInputValue)
          return (
            <FieldGroup key={`${index}index`}>
              <FieldLabel> {item.fieldLabel}</FieldLabel>
              {logoimg &&
                <PreviewWr>
                  <><CloseIconStyled data-test-id="remove_image_id" onClick={() => removeImage()} />
                    <PreviewImage src={fieldValue} alt="" /> </>
                </PreviewWr>
              }

              <TextField
                id={`${item.fieldName}-file-input`}
                variant="outlined"
                data-test-id={`${item.fieldName}_textfield`}
                onDragLeave={(e) => handleDrag(e, item.fieldName, setDragableFileField)}
                onDragEnter={(e) => handleDrag(e, item.fieldName, setDragableFileField)}
                onDragOver={(e) => handleDrag(e, item.fieldName, setDragableFileField)}
                onClick={() => handleOnIconClick(item.fieldName)}
                onDrop={(e) => handleDrop(e, item.fieldName, setDragableFileField, setFormData, formData)}
                placeholder={placeholder}
                value={fieldInputValue}
                InputProps={{
                  classes: {
                    input: classes.textStyle,
                  },

                  endAdornment: (
                    <InputAdornment position="end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                      >
                        <path
                          d="M14.2649 2.60898C12.0571 0.401172 8.46174 0.401172 6.25627 2.60898L0.139084 8.72148C0.0992406 8.76133 0.0781467 8.81523 0.0781467 8.87148C0.0781467 8.92773 0.0992406 8.98164 0.139084 9.02148L1.00393 9.88633C1.04346 9.92569 1.09697 9.94778 1.15276 9.94778C1.20854 9.94778 1.26205 9.92569 1.30158 9.88633L7.41877 3.77383C8.17815 3.01445 9.1883 2.59727 10.2617 2.59727C11.3352 2.59727 12.3453 3.01445 13.1024 3.77383C13.8617 4.5332 14.2789 5.54336 14.2789 6.61445C14.2789 7.68789 13.8617 8.6957 13.1024 9.45508L6.86799 15.6871L5.85783 16.6973C4.9133 17.6418 3.37815 17.6418 2.43362 16.6973C1.97658 16.2402 1.7258 15.6332 1.7258 14.9863C1.7258 14.3395 1.97658 13.7324 2.43362 13.2754L8.61877 7.09258C8.7758 6.93789 8.98205 6.85117 9.20236 6.85117H9.20471C9.42502 6.85117 9.62893 6.93789 9.78362 7.09258C9.94065 7.24961 10.025 7.45586 10.025 7.67617C10.025 7.89414 9.9383 8.10039 9.78362 8.25508L4.72815 13.3059C4.6883 13.3457 4.66721 13.3996 4.66721 13.4559C4.66721 13.5121 4.6883 13.566 4.72815 13.6059L5.59299 14.4707C5.63252 14.5101 5.68603 14.5322 5.74182 14.5322C5.7976 14.5322 5.85112 14.5101 5.89065 14.4707L10.9438 9.41758C11.4102 8.95117 11.6656 8.33242 11.6656 7.67383C11.6656 7.01523 11.4078 6.39414 10.9438 5.93008C9.98049 4.9668 8.41487 4.96914 7.45158 5.93008L6.85158 6.53242L1.26877 12.1129C0.889859 12.4896 0.589503 12.9377 0.38512 13.4314C0.180737 13.9251 0.0763961 14.4544 0.0781467 14.9887C0.0781467 16.0738 0.502365 17.0934 1.26877 17.8598C2.0633 18.652 3.10393 19.048 4.14455 19.048C5.18518 19.048 6.2258 18.652 7.01799 17.8598L14.2649 10.6176C15.3313 9.54883 15.9219 8.12617 15.9219 6.61445C15.9242 5.10039 15.3336 3.67773 14.2649 2.60898Z"
                          fill="black"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                className={
                  item.fieldName === dragableFileField ?
                    clsx(classes.textFieldStyle, classes.dropZoneField)
                    :
                    clsx(classes.textFieldStyle, classes.fileUploadField)
                }
              />
              <input
                data-test-id={`file_input_${item.fieldName}`}
                type="file"
                id={`file-input-${item.fieldName}`}
                className={classes.fileInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnFileChange(event, item.fieldName)}
                accept={item.accept}
              />
              <FieldBottomDesc>{item.accept}</FieldBottomDesc>
            </FieldGroup>
          );
        }

        if (item.type === "select") {
          return (
            <FieldGroup key={`${index}index`}>
              <FieldLabel> {item.fieldLabel}</FieldLabel>
              <Dropdown>
                <CustomSelectField
                  data-test-id="select_field_org"
                  placeholder="Select"
                  displayEmpty
                  classes={{ root: classes.muiSelectSelect }}
                  IconComponent={CustomDropdownIcon}
                  value={formData[item.fieldName as keyof FormData]}
                  onChange={(event: any) => {
                    setFormData({
                      ...formData,
                      [item.fieldName]: event.target.value
                    })
                  }}
                  disableUnderline
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        width: "404px",
                        maxWidth: " 100%",
                       },
                        className:classes.selectMenu
                      },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem disabled value="">
                    <span style={{ opacity: "0.45" }}>Select</span>
                  </MenuItem>
                  {item.options && item.options.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </Dropdown>
              {showErrors && item.isMandatory && formData[item.fieldName as keyof FormData] === "" &&
                <ErrorAlert>{item.errorMsg}</ErrorAlert>
              }
            </FieldGroup>
          )
        }
      })}

      <Button
        onClick={handleClick}
        className={isFormValid ? classes.formBtn : classes.disabledformBtn}
        data-test-id="next-button">
        Save
      </Button>

      {status == "success" && <Snackbar
        data-test-id={'snackbar-alert'}
        open={showSubmitAlert}
        onClose={() => setShowSubmitAlert(false)}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={status}>
          Organization Info Saved Successfully.
        </Alert>
      </Snackbar>
      }

    </FormBox >

  );
}

export default React.memo(OrganizationInfoForm);