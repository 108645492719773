import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  // Customizable Area Start
  Modal,
  Paper, Radio, Typography, IconButton
  // Customizable Area End
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import CustomDropDown from "../../../components/src/CustomDropDown.web";
import {checkbox, checked, close} from "../../customform/src/assets"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const UserDropdown = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  maxWidth: "419px",
  border: '1px solid rgba(0, 0, 0, 0.2)',
})

const UserSelectField = styled(Select)({
  height: "48px",
  borderRadius: "8px",
  padding: "10px !important",
  "& *": {
    background: "#fff !important;",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
  },
  '&:focus': {
    borderColor: '#cccccc',
  },
})


const CustomButton = styled('button')({
  borderRadius: "8px",
  backgroundColor: "#43d270",
  maxWidth: "128px",
  height: "40px",
  display: "flex",
  flexDirection: "row",
  padding: "8px 8px 8px 12px",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  fontFamily: "Inter",
  border: "none",
  cursor: "pointer",
  marginRight: "28px",
  '@media (max-width:600px)':{
    marginRight:'0px !important'
  }
});

const AddButton = styled('button')({
  borderRadius: "8px",
  flexDirection: "row",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  fontFamily: "Inter",
  border: "none",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "12px"
});

const CustomTypography = styled("b")({
  lineHeight: "160%",
  display: "inline-block",
  width: "105px",
  });

const renderTasks = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.resourcename, color: selected ? "#34373A" : "#7D7D7F", }}>{option.title}</Typography>
    </li>
  );
}


// Customizable Area End

import CustomformController, {Props} from "../../customform/src/CustomformController";
import { Formik } from "formik";

export default class AddUserForm extends CustomformController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // This is used to fetch page data dynamically on search routes
  async componentDidMount() {
    // Customizable Area Start
    this.getTasks()
    this.getDepartments();
    // Customizable Area End
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '-71px' }}>
          <CustomButton name="adduser" onClick={this.handleShowMemberForm}>
            <CustomTypography>+ Add New User</CustomTypography>
          </CustomButton>
        </div>
        <Modal open={this.state.isShowForm} onClose={this.handleClose} style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} data-test-id="modalClose" >
          <Paper style={{
            width: '437px',
            maxWidth: "100%",
            padding: '16px',
            borderRadius: '12px',
            overflow: 'scroll',
            maxHeight: '90vh',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none'
          }}>
            <Box style={webStyle.headcontainer}>
              <Typography style={webStyle.title}>Add New User</Typography>
              <IconButton style={{ padding: '5px' }} onClick={this.handleClose}><img src={close} alt="close" />
              </IconButton>
            </Box>
            <Formik
              initialValues={this.state.initialValuesUser}
              validationSchema={this.state.UserSchema}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={this.handleAddUser}
              data-testid="formik_form"
            >
              {formik => {
                const {
                  values,
                  touched,
                  handleChange,
                  handleSubmit,
                  errors,
                  handleBlur
                } = formik;
                return (
                  <form onSubmit={handleSubmit} data-testid="dataform">
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="fname"
                          style={webStyle.inputlabels as CSSProperties}
                        >
                          First Name
                        </InputLabel>
                        <TextField
                          id="fname"
                          data-testid="fname"
                          style={webStyle.inputStyle}
                          name="fname"
                          placeholder="Enter first name"
                          fullWidth
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          margin="normal"
                          value={values.fname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {this.handleErrors(errors, touched, 'fname') ? (
                          <div style={webStyle.errors}>{errors.fname}</div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="lname"
                          style={webStyle.inputlabels as CSSProperties}
                        >
                          Last Name
                        </InputLabel>
                        <TextField
                          id="lname"
                          data-testid="lname"
                          name="lname"
                          placeholder="Enter last name"
                          fullWidth
                          variant="standard"
                          InputProps={{
                            disableUnderline: true
                          }}
                          style={webStyle.inputStyle}
                          margin="normal"
                          value={values.lname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {this.handleErrors(errors, touched, 'lname') ? (
                          <div style={webStyle.errors}>{errors.lname}</div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="email"
                          style={webStyle.inputlabels as CSSProperties}
                        >
                          Email
                        </InputLabel>
                        <TextField
                          id="email"
                          data-testid="email"
                          name="email"
                          placeholder="Enter email address"
                          fullWidth
                          variant="standard"
                          InputProps={{
                            disableUnderline: true
                          }}
                          style={webStyle.inputStyle}
                          margin="normal"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {this.handleErrors(errors, touched, 'email') ? (
                          <div style={webStyle.errors}>{errors.email}</div>
                        ) : null}
                        {this.state.errors.email ? (
                          <div style={webStyle.errors}>{this.state.errors.email}</div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <InputLabel
                          htmlFor="department"
                          style={webStyle.Selectlabel as CSSProperties}
                        >
                          Department
                        </InputLabel>
                        <UserDropdown>
                          <UserSelectField
                            id="department"
                            name="department"
                            displayEmpty
                            data-testid='menu_item_data'
                            placeholder="Enter department"
                            IconComponent={this.CustomdownIcon}
                            onChange={handleChange}
                            value={values.department}
                            onBlur={handleBlur}
                            disableUnderline
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                  width: "404px",
                                  maxWidth: " 100%",
                                },
                                className: "dropDownMenu"
                              },
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                              },
                              getContentAnchorEl: null,
                            }}
                            style={{ color: values.department ? 'black' : "#a2a2a2" }}
                          >
                            <MenuItem disabled value="">
                              Enter department
                            </MenuItem>
                            {
                              this.state.departmentList.map((item: any) => (
                                <MenuItem key={item.name} style={{color: 'black', fontFamily: "Lato"}}
                                  value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))
                            }
                          </UserSelectField>
                        </UserDropdown>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <InputLabel
                          htmlFor="task"
                          style={webStyle.Selectlabel as CSSProperties}
                        >
                          Assign Task
                        </InputLabel>
                        <CustomDropDown
                          placeholder={values.task?.length ? "" : "Select from list"}
                          value={values.task}
                          multiple
                          List={this.state.tasklist}
                          getOptionLabel={(option: any) => option.title}
                          onChange={(event: any, newValue: any) => {
                            handleChange({
                              target: {
                                name: 'task',
                                value: newValue,
                              },
                            });
                          }}
                          renderOption={renderTasks}
                          data-test-id={'assign_task'}
                        />
                      </Grid>
                      <Box
                        display="flex"
                        justifyContent="center"
                        mt={3}
                        width="100%"
                      >
                        <AddButton
                          type="submit"
                          data-testid="submitButton"
                          style={{ ...webStyle.addbutton, backgroundColor: this.isGoNext(errors, values) } as any}
                        >
                          Add User
                        </AddButton>
                      </Box>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Paper>
        </Modal>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  addbutton: {
    width: "100%",
    height: "40px",
    backgroundColor: "#43d270",
    color: "black",
  },
  inputlabels: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    marginTop: "10px",
    color: "#34373a"
  },
  Selectlabel: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    marginTop: "10px",
    marginBottom: '8.5px'
  },
  errors: {
    color: "red",
    marginTop: "10px",
    fontFamily: 'Lato',
  },
  inputStyle: {
    border: "#D9D9D9 solid 1px",
    borderRadius: "4px",
    padding: "8.5px 14px",
    font: "inherit",
    height: "48px",
  },
  resourcename: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: '14px'
  },
  title: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "25.2px",
    fontSize: '18px',
  },
  headcontainer: {
    width: '100%',
    height: '41px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginbottom: '16px'
  }
}
// Customizable Area End
