import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {
  styled,
  TextField,
  Typography as TypographyMUI,
  Button as ButtonMUI,
  withStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form, FieldArray } from "formik";
import Layout from "../../dashboard/src/Layout.web";
import InputRows from "./components/InputRows";
import { infoIcon } from "./assets";

const Container = styled("div")({
  width: "100%",
  padding: "32px 44px 20px 44px"
});

const PageContent = styled("div")({
  marginBottom: "23px"
});

const HeadingRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "17px"
});

const MainHeading = styled(TypographyMUI)({
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "27.5px"
});

const CustomButton = styled(ButtonMUI)({
  minHeight: "40px",
  minWidth: "97px",
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  backgroundColor: "#43D270",
  color: "#34373A",
  textTransform: "none",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  '& span': {
    fontFamily: "Inter",
    fontWeight: 700,
  }
});

const DisabledButton = styled(CustomButton)({
  pointerEvents: "none",
  background: "#B1B3C3",
  "&:hover": {
    background: "#B1B3C3"
  }
});

const Text = styled(TypographyMUI)({
  color: "#7D7D7F",
  textAlign: "left",
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "19.2px"
});

const TextInput = styled(TextField)({
  width: '100%',
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  marginBottom: 40,
  '& *': {
    borderColor: "rgba(0, 0, 0, 0.2) !important",
  },
});

const LabelStyled = styled(TypographyMUI)({
  fontSize: "15px",
  fontFamily: "Lato",
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "8px"
});

const AddMoreButton = styled(ButtonMUI)({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "19.2px",
  textAlign: "right",
  color: "#43D270",
  textTransform: "capitalize",
  marginBottom: "30px",
});

const TooltipContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const InfoIcon = styled("img")({
  height: "16px",
  width: "16px",
  marginLeft: "10px",
  marginBottom: "8px",
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#7D7D7F",
    width: "274.427px",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%",
    display: "inline-flex",
    height: "71px",
    padding: "7px 0px 7px 16.048px",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    background: "#FFF",
    boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.11)"
  },
}))(Tooltip);

const TextResult = styled(TextInput)({
  width: "404px",
  "@media all and (max-width: 600px)": {
    width: "100%"
  }
});

const CircularProgressContainer = styled("div")({
  height: "calc(100vh - 145px)", 
	color: "grey", 
  display: "flex", 
  justifyContent: "center",
  alignItems: "center", 
});

const tooltipDescription = "Total CO2 emissions generated by the activity, measured in kilograms";

// Customizable Area End

import CfclimatiqshippingfreightController, {
  Props,
  configJSON,
} from "./CfclimatiqshippingfreightController";

export default class Cfclimatiqshippingfreight extends CfclimatiqshippingfreightController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout>
        {
          this.state.isLoading ? (
            <CircularProgressContainer>
              <CircularProgress color="inherit" />
            </CircularProgressContainer>
          ) : (
            <Container>
              <Formik
                enableReinitialize
                initialValues={{
                  inputRows: this.state.inputRows
                }}
                onSubmit={(values: unknown) => alert(JSON.stringify(values))}
              >
                {formikProps => {
                  return (
                    <>
                      <PageContent>
                        <HeadingRow>
                          <MainHeading>{this.state.pageTitle}</MainHeading>
                          {true ?
                            <DisabledButton data-test-id="save_button" >Save</DisabledButton>
                            :
                            <CustomButton data-test-id="save_button">Save</CustomButton>
                          }
                        </HeadingRow>
                        <Text>{this.state.pageDescription}</Text>
                      </PageContent>
                      <Form>
                        <FieldArray
                          name="inputRows"
                          render={arrayHelpers => {
                            return (
                              <>
                                <InputRows
                                  createLabel={this.generateLabelFromParamName}
                                  createOptions={this.generateOptionsFromParamName}
                                />
                                <AddMoreButton
                                  type="button"
                                  data-test-id="add-another-item-btn"
                                  onClick={() => arrayHelpers.push(this.initialRow)}
                                >
                                  + Add Another Item
                                </AddMoreButton>
                                <br />
                                <CustomButton
                                  style={{ width: "207px", height: "40px", margin: "40px 0" }}
                                  type="submit"
                                >
                                  Calculate
                                </CustomButton>
                              </>
                            );
                          }}
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
              <TooltipContainer>
                <LabelStyled>Resulting CO2 in kgs</LabelStyled>
                <LightTooltip title={tooltipDescription}>
                  <InfoIcon src={infoIcon} alt="info icon" />
                </LightTooltip>
              </TooltipContainer>
              <TextResult
                data-test-id="co2Result"
                id="outlined-basic"
                variant="outlined"
                value={this.state.co2Result}
                inputProps={{
                  style: { fontSize: "15px" }
                }}
                InputProps={{
                  style: {
                    height: "50px",
                    borderRadius: "8px",
                  }
                }}
              />
            </Container>
          )
        }

      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
