import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Grid,
  TextField,
  Box,
  InputLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FormCircleIcon } from "../../../blocks/DynamicContent/src/assets";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: "500px",
    margin: "50px auto 2px auto",
    '& form': {
      margin: 0,
    }
  },
  btn: {
    background: "#B1B3C3",
    borderRadius: "8px",
    padding: "16px 12px",
    width: "186px",
    color: "#101010",
    fontSize: "15px",
    fontWeight: 700,
    display: "flex",
    margin: "auto",
  },
  labelInput: {
    margintTop: '20px',
    color: "#34373A",
    marginBottom: '8px',
    lineHeight: '22px',
    fontSize: "15px",
    fontFamily: "Lato"
  },
  fieldGroupBox: {
    marginBottom: '50px',
  },
  textFieldBorder: {
    borderRadius: "8px",
    padding: "8.5px 14px",
    font: "inherit",
    marginTop: 0,
    background: '#FFFFFF',
    height: "48px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D9D9D9",
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#D9D9D9",
        borderWidth: "2px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D9D9D9",
        borderWidth: "2px",
      },
    },
  },
  label: {
    color: "#34373A",
    lineHeight: "22px",
    marginBottom: theme.spacing(1),
  },
  select: {
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
    marginBottom: '20px',
    marginTop: 0,
    color: "#B1B3C3 !important",
    font: 'inherit',
    height: '48px',
    padding: '8.5px 14px',
    background: '#FFFFFF',
    appearance: 'none',
    width: '100%',
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      color: "#b1b3c3",
    },
    '& div:focus': {
      backgroundColor: "rgba(0, 0, 0, 0) !important",
    },
    '& *': {
      color: "#34373A !important",
    }
  },
  selectedGroup: {
    position: "relative",
  },
  selectselected: {
    marginTop: 0,
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
    color: "#34373A !important",
    marginBottom: '20px',
    font: 'inherit',
    height: '48px',
    padding: '8.5px 14px',
    appearance: 'none',
    width: '100%',
    background: '#FFFFFF',
  },
  icon: {
    backgroundImage: `url(${FormCircleIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right 10px",
    paddingRight: "65px",
    color: "#fff",
    cursor: "pointer",
    "& .MuiSelect-icon": {
      color: "transparent",
    },
    "& svg": {
      display: 'none'
    }
  },
  red: {
    color: "red",
    marginTop: "10px",
    fontFamily: "Lato"
  },
  heading: {
    fontSize: "15px",
    fontWeight: 400,
    fontFamily: "lato",
    lineHeight: "22px",
    marginBottom: "8px",
    color: "#34373A",
  },
  listType: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  listItemText: {
    position: "relative",
    padding: "8px 1.5rem",
    display: "flex",
    fontFamily: "lato",
    alignItems: "center",
    color: "#9A9A9A",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: 0,
      top: "12px",
      width: "9px",
      height: "9px",
      borderRadius: "50%",
      backgroundColor: "transparent",
      border: "1px solid #8C8C8C",
    },
  },
  checkbox: {
    marginTop: 0,
    marginBottom: "20px",
    color: "rgba(0, 0, 0, 0.54)",
    "& .Mui-checked": {
      color: "rgba(67,210,112)",
    },
  },
  radioLabelStyle: {
    color: "#9A9A9A",
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: 400,
  }
}));

interface PropTypes {
  data?: any;
  validation?: any;
  setValidation?: any;
  setEnableNext?: any;
  errorMessage?: boolean;
  formData?: any;
  setFormData?: any;
  validEmail?: boolean;
  setValidEmail?: any;
  validCompany?: boolean;
  setValidCompany?: any;
}

interface FieldErrors {
  [key: string]: string;
}

const StepForm = ({
                    data,
                    validation,
                    setValidation,
                    setEnableNext,
                    errorMessage,
                    validEmail,
                    setValidEmail,
                    validCompany,
                    setValidCompany
                  }: PropTypes) => {
  const positionQuestion = data?.sort((a: any, b: any) => {
    return a.attributes.question_position - b.attributes.question_position;
  })

  const classes = useStyles();
  const initialValues: { [key: string]: any } = {};
  const formik = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const borderColor = (isMandatory: boolean, nextClicked: any, isValid: any, question: any) => {
    if (question === 'Email Address' && nextClicked && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formik.values[question])) {
      return "red solid 1px"
    }
    return isMandatory && nextClicked ? isValid ? "1px solid #D9D9D9" : "red solid 1px" : "1px solid #D9D9D9"
  }

  const errorText = (isMandatory: boolean, nextClicked: any, isValid: any) => {
    if (isMandatory && nextClicked && (!isValid || (Array.isArray(isValid) && !isValid.length))) {
      return <div className={classes.red}>{'Required question and needs to be answered'}</div>;
    }
    return null;
  }

  useEffect(() => {
    setValidation(formik.values);
  }, [formik.values]);

  useEffect(() => {
    const areAllRequiredFieldsFilled = positionQuestion?.every(
      (question: any) => {
        if (question?.attributes?.question_mandatory) {
          const value = validation[question.attributes.question];
          if (question.attributes.question === 'Email Address') {
            setValidEmail(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
          }
          if (question.attributes.question === 'Company Name') {
            setValidCompany(value !== '')
            return value !== '';
          }
          if (Array.isArray(value)) {
            return value.length !== 0;
          }
          return value !== undefined && value !== "";
        }
        return true;
      }
    );
    setEnableNext(areAllRequiredFieldsFilled || false);
  }, [validation]);

  const isEmpty = (value: string) => value.trim() === "";

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const validateFieldName = (fieldName: string, value: string) => {
    if (isEmpty(value)) {
      setFieldErrors(prevErrors => ({
        ...prevErrors,
        [fieldName]: `${fieldName} should not be empty.`
      }));
    } else {
      setFieldErrors(prevErrors => ({
        ...prevErrors,
        [fieldName]: ''
      }));
    }
  };

  return (
    <Box className={classes.form}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ width: '100%', margin: 0 }}>
          {
            positionQuestion && positionQuestion.map((question: any, index: any) => {
              return (<Grid item xs={12}>
                <Box className={classes.fieldGroupBox}>
                  <InputLabel htmlFor={question?.attributes?.question} className={classes.labelInput}>
                    {question?.attributes?.question}
                  </InputLabel>
                  {question?.attributes?.question_type === 'text' &&
                    <TextField
                      id={question?.attributes?.question}
                      name={question?.attributes?.question}
                      placeholder={question?.attributes?.question_placeholder}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{ border: borderColor(question?.attributes?.question_mandatory, errorMessage, validation[question?.attributes?.question], question?.attributes?.question) }}
                      margin="normal"
                      onChange={(e) => {
                        formik.handleChange(e);
                        validateFieldName(question?.attributes?.question, e.target.value);
                      }}
                      value={formik.values[question?.attributes?.question]}
                      className={clsx(classes.textFieldBorder)}
                    />
                  }
                  {
                    !validEmail && errorMessage && question.attributes.question === 'Email Address' && formik.values[question.attributes.question] ?
                      <div className={classes.red}>{'Email is not valid'}</div> : null
                  }
                  {
                    errorMessage && question.attributes.question === 'Email Address' && !formik.values[question.attributes.question] ?
                      <div className={classes.red}>{'Email should not be empty'}</div> : null
                  }
                  {
                    !validCompany && errorMessage && question.attributes.question === 'Company Name' ?
                      <div className={classes.red}>{'Company Name should not be empty'}</div> : null
                  }
                  {
                    fieldErrors[question?.attributes?.question] && (validCompany || validEmail) &&
                      <div className={classes.red}>{fieldErrors[question?.attributes?.question]}</div>
                  }
                  {question?.attributes?.question_type === 'dropdown' &&
                    <select
                      id={question?.attributes?.question}
                      name={question?.attributes?.question}
                      placeholder={question?.attributes?.question_placeholder}
                      style={{
                        fontFamily: "Lato",
                        border: borderColor(question?.attributes?.question_mandatory, errorMessage, validation[question?.attributes?.question], question?.attributes?.question)
                      }}
                      onChange={formik.handleChange}
                      value={formik.values[question?.attributes?.question]}
                      className={formik.values[question?.attributes?.question] ? clsx(classes.selectselected, classes.icon) : clsx(classes.select, classes.icon)}
                    >
                      <option value="" style={{color: "#B1B3C3"}}>Select One</option>
                      {question?.attributes?.question_options?.map((option: { value: number, label: string }) => (
                        <option key={option?.value} value={option?.value}>
                          {option?.label}
                        </option>
                      ))
                      }
                    </select>
                  }
                  {
                    question?.attributes?.question_type === 'checkbox' &&
                    <FormGroup
                      aria-labelledby={`radio-btn-${index}`}
                      className={classes.checkbox}
                    >
                      {
                        errorText(question?.attributes?.question_mandatory, errorMessage, validation[question?.attributes?.question])
                      }
                      {question?.attributes?.question_options.map((item: any) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={question?.attributes?.question}
                              value={item.value}
                              onChange={formik.handleChange}
                            />}
                          label={item.label}
                        />
                      ))}
                    </FormGroup>
                  }
                  {
                    question?.attributes?.question_type === 'radio' &&
                    <RadioGroup
                      aria-labelledby={`radio-btn-${index}`}
                      name={question?.attributes?.question}
                      value={formik.values[question?.attributes?.question] && formik.values[question?.attributes?.question].toString()}
                      onChange={formik.handleChange}
                    >
                      {
                        question?.attributes?.question_mandatory && errorMessage ? validation[question?.attributes?.question] ? null : <div className={classes.red}>{formik.errors.measure}</div> : null
                      }
                      {question?.attributes?.question_options.map((item: any) => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value && item.value.toString()}
                          control={<Radio />}
                          label={item.label}
                          className={classes.radioLabelStyle}
                        />
                      ))}
                    </RadioGroup>
                  }
                </Box>
              </Grid>)
            })
          }
        </Grid>
      </form>
    </Box>
  );
};
export default StepForm;
